import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ConfirmationDialog from './ConfirmationDialog';

const StatusCell = (props) => {

    var isNew = !props.dataItem.idParent;

    return (
        <td style={{ background: props.isApresTravaux && (isNew || props.dataItem.validated) ?"green":"white"} }>
           
           
        </td>
    );
}
export default withTranslation()(StatusCell);
