import { React, useState, useRef, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import i18next from 'i18next';
import { useParams, useNavigate, Link } from "react-router-dom";
import 'react-html5-camera-photo/build/css/index.css';
import { getBaseURL } from "../AccessAPI";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs";
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import {
    Label,
    Error,
} from "@progress/kendo-react-labels";
import { Fade } from "@progress/kendo-react-animation";
const videoConstraints = {
    width: 600,
    height: 600,
    facingMode: 'environment',
}

function Piece({ t }) {
    let { constatid, pieceid } = useParams();
    const [title, setTitle] = useState(t('CreatePieceTitle'));
    const [loading, setLoading] = useState(true);
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const navigate = useNavigate();
    const [mandatId, setMandatId] = useState('');
    const [picture, setPicture] = useState('')
    const webcamRef = useRef(null);
    const [currentPiece, setCurrentPiece] = useState(null);
    const [overwriteImage, setOverwriteImage] = useState(false);
    const [errors, setErrors] = useState([]);
    const [niveauList, setNiveauList] = useState([]);
    const [currentNiveau, setCurrentNiveau] = useState(null);
    const [zoneDescription, setZoneDescription] = useState('');
    const [isApresTravaux, setIsApresTravaux] = useState(false);

    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot({ width: 3840, height: 3840 });
        //console.log(pictureSrc);
        setOverwriteImage(true);
        setPicture(pictureSrc);
    });
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const labelStyle = {
        marginBottom: 0,
        fontSize: 14,
        marginLeft: 0,
    };
    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 0,
        width: 300,
        placeholder: null
    };


    const handleFileInput = useRef(null);

    const handleClick = () => {
        handleFileInput.current.click();
    };

    const handleImageChange = (event) => {

        try {
            let file = event.target.files[0];



            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let pictureFromTempFile = reader.result.toString();
                setPicture(pictureFromTempFile);
                setOverwriteImage(true);

            };
            reader.onerror = (error) => {
                console.log(error);
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('Error') });
            };
        }
        catch (e) {
            console.log(e);
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('Error') });
        }
    };

    const handleChangeDescription = useCallback(
        (e) => {
            setCurrentPiece(existingValues => ({
                ...existingValues,
                description: e.value,
            }))
        },
        [setCurrentPiece]
    );


    const { success, error, warning, info, none, notificationMessage } = notificationState;


    const savePiece = async () => {
        setLoading(true);

        console.log('Saving piece');
        const dataItemPiece = {
            idConstat: constatid,
            id: pieceid,
            idNiveau: currentPiece.idNiveau,
            imageString: picture,
            overwriteImage: overwriteImage,
            description: currentPiece.description,
            idZone:currentPiece.idZone,
            nom:currentPiece.nom,
            isEditedByUser: true
        };

        const result = await MandatManager.updatePiece(dataItemPiece);

        if (result && result.errors == null && result.imageFileExists) {
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessSavingObject') });

            setLoading(false);

            navigate("/constatint/" + constatid+'/zone/' + currentPiece.idZone);
        }
        else if (result && !result.imageFileExists) {
            //error image file does not exist on server
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ImageFileMissing') });

            setLoading(false);

        }
        else {
            //error object not saved
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') });

            setLoading(false);

        }
        return result;
    };

    const onChangeNiveau = (e) => {

        setCurrentPiece(existingValues => ({
            ...existingValues,
           idNiveau: e.target.value.id,
        }));
        setCurrentNiveau(e.target.value);
    };

    const onCloseNiveau = (e) => {

        setCurrentNiveau(null);
    };



    useEffect(() => {



        const fetchNiveauType = async () => {
            MandatManager.getCatalogValuesList('niveautype')
                .then(function (result) {
                    if (result) {
                        setNiveauList(result);
                    }
                })
                .catch(function (error) {

                    console.log(error);
                });


        };


        const fetchData = async () => {
            console.log('fetch piece detail');
            let baseUrl = await getBaseURL();
            MandatManager.getPieceDetail(pieceid)
                .then(function (result) {

                    if (result) {
                        setMandatTitle(result.mandatDescription);
                        setParcelleTitle(result.parcelleDescription);
                        setZoneDescription(result.zoneDescription);


                        if (result.idParent) {
                            setIsApresTravaux(true);
                        }


                        if (result.imageString) {
                            setPicture('data:image/png;base64,' + result.imageString);
                            setOverwriteImage(false);
                        }
                        else {

                            var imageUrl = '';
                            if (result.imagePath) {
                                // Create a timestamp
                                var timestamp = new Date().getTime();

                                //remove first character='.'
                                imageUrl = baseUrl + result.imagePath.slice(1) + "?t=" + timestamp;
                            }
                            setOverwriteImage(false);
                            setPicture(imageUrl);

                        }
                        setMandatId(result.idMandat);

                        setCurrentPiece(result);
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                    setErrors(error.message);
                });
        };

        setLoading(true);

        fetchNiveauType().then(fetchData());
 

    }, []);



    useEffect(() => {
        if (currentPiece && currentPiece.idNiveau)
            setCurrentNiveau(niveauList.find(x => x.id == currentPiece.idNiveau));


    }, [niveauList, currentPiece]);

    const errorLabelStyle = {
        marginBottom: -10,
        fontSize: 12,
        marginLeft: 10,
        color: "red",
        fontStyle: "italic",

    };


    let contentsPiece = loading ?
        <div>
            <p><em>{i18next.t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
        </div>
        :
        errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <div>{t(notificationMessage)}</div>
                            </Notification>
                        )}

                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span style={{ whiteSpace: "pre-line", maxWidth: 200 }}>{t(notificationMessage)}</span >
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div>
                    <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{title}</h4>


                    <Link to={'/constatint/' + constatid}>
                        <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
                    </Link>
                    <Link to={'/mandatdetail/' + mandatId}>
                        <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
                    </Link>
                    
                   

                    <Link to={'/constatint/' + constatid + '/zone/' + currentPiece.idZone}>
                        <p style={{ marginLeft: 10 }}><i>{zoneDescription} </i></p>
                    </Link>

                    <h4 style={{ marginLeft: 10, marginTop: 5 }}>{currentPiece.nom}</h4>


                    {!isMobile &&
                        <div>
                            {picture != '' ? (
                                <div>
                                    <Button icon='cancel'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOverwriteImage(true);
                                            setPicture('');
                                        }} disabled={isApresTravaux}
                                    >
                                    </Button>
                                    <br />
                                    <img style={{ height: 300 }} src={picture} key="imagePiece" id="imagePiece" />
                                </div>
                            ) :
                                (<div>
                                    <Button icon='camera'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }} disabled={isApresTravaux}
                                    >
                                    </Button>
                                    <Error>{t('TakePhoto')} </Error>
                                    <Webcam
                                        audio={false}
                                        height={300}
                                        ref={webcamRef}
                                        width={300}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                        forceScreenshotSourceSize="true"
                                    />
                                </div>
                                )}

                        </div>
                    }

                    {isMobile &&

                        <div>
                            <Button icon='camera' onClick={handleClick} disabled={isApresTravaux}></Button>
                            <br />
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    ref={handleFileInput}
                                    onChange={handleImageChange}
                                />
                            </label>


                            {picture && <img style={{ height: 300 }} src={picture} />}

                        </div>

                    }
                    <br />




                    <p style={labelStyle}><i>{t('Niveau')} </i></p>
                    <ComboBox
                        required="true"
                        style={fieldStyle}
                        data={niveauList}
                        textField="libelle"
                        dataItemKey="id"
                        defaultItem="Niveau"
                        adaptive={true}
                        value={currentNiveau}
                        onChange={onChangeNiveau}
                        clearButton={false}
                        onClose={onCloseNiveau}
                        disabled={isApresTravaux}
                    />
                    <br />
                    {!currentNiveau && <Label style={errorLabelStyle}>{t('RequiredField')} </Label>}




                    <p style={labelStyle} ><i>{t('Description')}  </i></p>
                    <TextArea style={fieldStyle} rows={3} value={currentPiece.description} onChange={handleChangeDescription} disabled={isApresTravaux} />
                    <br />


                    <br />
                    <Button icon="save" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }} disabled={!picture}
                        onClick={savePiece}>{t('SavePiece')}</Button>
                    <br /><br />

                </div>
            </div>
            : <p><em>{t(errors)}</em></p>;;
    return (
        <div>

            {contentsPiece}
        </div>);
}
export default withTranslation()(Piece);
