// src/ComboBoxComponent.js
import React, { useState, useEffect } from 'react';
import AutreDegat from './AutreDegat';
import Fissure from './Fissure';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from "react-router-dom";

import { ComboBox } from "@progress/kendo-react-dropdowns";
import ConstatNavigationDetail from './ConstatNavigationDetail';

function Degat({ t })
{
    const params = useParams();
 
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        sessionStorage.setItem('selectedOptionStored', event.target.value.value);
    };

    const options = [
        { value: 0, label: i18next.t('Fissure')  },
        { value: 1, label:  i18next.t('AutreDegat')  }
    ];
  
    const [selectedOption, setSelectedOption] = useState(options[0] );
   

    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 10,
        width: 300,
        placeholder: null
    };
    useEffect(() => {
        const selectedOptionStored = sessionStorage.getItem('selectedOptionStored');
        if (selectedOptionStored) {
            setSelectedOption(options[selectedOptionStored]);
        }
    }, []);



  

    const renderSelectedComponent = () => {
      
        if (selectedOption) {
            switch (selectedOption.value) {
                case 0:
                    params.numero = "create";
                    return <Fissure params={params} />;
                case 1:                    
                    params.numero = "create";
                    return < AutreDegat params={params} />;
            }
        }             
    };

    return (
        <div>
            <h3>{i18next.t('DegatCreate')}</h3>

            < ConstatNavigationDetail params={params} />

            <br>
            </br>
            <ComboBox
                required={true}
                style={fieldStyle}
                data={options}
                textField="label"
                value={selectedOption}
                dataItemKey="value"
                onChange={handleChange}
                clearButton={false}
            />

            <br /> <br /> 
            <div>
                {renderSelectedComponent()}
            </div>
        </div>
    );
}
export default withTranslation()(Degat);