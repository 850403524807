
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams, useNavigate, Link } from "react-router-dom";
import MandatManager from './MandatManager';
import { Loader } from "@progress/kendo-react-indicators";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
function ConstatNavigationDetail({ t }) {


    const params = useParams();
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [currentFacade, setCurrentFacade] = useState(null);
    const [currentMur, setCurrentMur] = useState(null);
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });
    const { success, error,  notificationMessage } = notificationState;


 
    useEffect(() => {
        const fetchData = async () => {

            if (params.facadeid) {
                MandatManager.getFacadeDetail(params.facadeid)
                    .then(function (result) {
                        if (result) {
                            setCurrentFacade(result);
                            setMandatTitle(result.mandatDescription);
                            setParcelleTitle(result.parcelleDescription);
                            setLoading(false);
                        }
                    }
                    )
                    .catch(function (error) {
                        setLoading(false);
                        setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                        console.log(error);
                        setErrors(error.message);
                    });
            }
            else if (params.murid) {
                MandatManager.getMurDetail(params.murid)
                    .then(function (result) {
                        if (result) {
                            setCurrentMur(result);
                            setMandatTitle(result.mandatDescription);
                            setParcelleTitle(result.parcelleDescription);
                            setLoading(false);
                        }
                    }
                    )
                    .catch(function (error) {
                        setLoading(false);
                        setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                        console.log(error);
                        setErrors(error.message);
                    });
            }

        };
        fetchData();
    }, []);

    let contentsConstatNav = loading ?
        <div>
            <p><em>{i18next.t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>

        </div>

        :
        errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{i18next.t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{i18next.t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>


                <div>
                    {currentFacade &&
                        <div>
                            <Link to={'/constat/' + params.constatid + '/facade/' + currentFacade.id}><p style={{ marginLeft: 10 }}><i>{i18next.t('Facade :')} {currentFacade.position} </i></p></Link>

                            <Link to={'/constat/' + params.constatid}>
                                <p style={{ marginLeft: 10 }}><i>{i18next.t('Parcelle :')} {parcelleTitle} </i></p>
                            </Link>
                            <Link to={'/mandatdetail/' + currentFacade.idMandat}>
                                <p style={{ marginLeft: 10 }}><i>{i18next.t('Mandat :')} {mandatTitle} </i></p>
                            </Link>
                        </div>}

                    {currentMur &&
                        <div>
                            < Link to={'/constatint/' + params.constatid}>
                                <p style={{ marginLeft: 10 }}><i>{i18next.t('Parcelle :')} {parcelleTitle} </i></p>
                            </Link>
                            <Link to={'/mandatdetail/' + currentMur.idMandat}>
                                <p style={{ marginLeft: 10 }}><i>{i18next.t('Mandat :')} {mandatTitle} </i></p>
                            </Link>
                            < Link to={'/constatint/' + params.constatid + "/zone/" + currentMur.idZone}><p style={{ marginLeft: 10 }}><i>{i18next.t('Zone :')} {currentMur.zoneDescription} </i></p></Link>
                            < Link to={'/constatint/' + params.constatid + "/zone/" + currentMur.idZone + "/piecemurs/" + currentMur.idPiece}><p style={{ marginLeft: 10 }}><i>{i18next.t('Piece :')} {currentMur.pieceDescription} </i></p></Link>
                            < Link to={'/constatint/' + params.constatid + "/zone/" + currentMur.idZone + "/piecemurs/" + currentMur.idPiece + "/mur/" + currentMur.id}><p style={{ marginLeft: 10 }}><i>{i18next.t('Mur :')} {currentMur.nom} </i></p></Link>

                        </div>}

                </div>
                </div>
                : <p><em>{i18next.t(errors)}</em></p>;
   
    return (
        <div>
            {contentsConstatNav}
        </div>  );
}
export default withTranslation()(ConstatNavigationDetail);