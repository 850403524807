// src/ComboBoxComponent.js
import React from 'react';
import AutreDegat from './AutreDegat';
import ConstatNavigationDetail from './ConstatNavigationDetail';

import { withTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

function AutreDegatDetail({ t }) {

    const params = useParams();

    return (
        <div>        
            <br />  
            <div>                
               < ConstatNavigationDetail params={params} />
               < AutreDegat params={params} />
            </div>
        </div>
    );
}
export default withTranslation()(AutreDegatDetail);