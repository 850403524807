import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";

import { Form, Field, FormElement } from "@progress/kendo-react-form";

import { withTranslation } from 'react-i18next';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import MandatManager from '../mandat/MandatManager';
const initialSort = [{
    field: 'roleName',
    dir: 'asc'
}];



function UserRightsDialog(props) {
    const [loading, setLoading] = React.useState(true);
    const [allRoles, setAllRoles] = React.useState([]);
    const [sort, setSort] = React.useState(initialSort);
    const [visibility, setVisibility] = React.useState(props.item ? false : true);
    const [title, setTitle] = React.useState(props.item ? props.t("UserRights") + " " + props.item.userName : props.t("UserRights"));
    const [mandats, setMandats] = React.useState([]);
    const [errors, setErrors] = React.useState('');
    React.useEffect(() => {
        const fetchData = async () => {
            console.log('fetch mandats');
            MandatManager.getMandatsUser(props.item.id)
                .then(function (result) {

                    if (result) {
                        result.map((item) => {
                            item.id = item.id;
                            item.numero = item.numero;
                            item.clientName = item.clientName;
                            item.fullTextSearch = item.fullTextSearch;
                        });

                        setMandats(result);
                        setLoading(false);
                        setSort(sort);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };
        fetchData();

    }, []);



    let contents = loading
        ? <p><em>{props.t('Loading')} </em></p>
        : errors.length == 0 ?
            <div >

                <div style={{ marginLeft: 10 }}>
                    <Grid
                        style={{
                            height: "430px",
                        }}
                        name="MandatsGrid"
                        data={orderBy(mandats, sort)}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setMandats(orderBy(mandats, e.sort));
                               
                            }
                        }

                        resizable={true}
                    >


                        <Column field="numero" filterable={false} title={props.t('Mandat')} />
                        <Column field="clientName" filterable={false} title={props.t('ClientName')} />

                    </Grid>

                </div>
                <br></br>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <button
                        type={"submit"}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={props.closeViewMandats} style={{width:200} }
                    >
                        {props.t('Fermer')}
                    </button>
                </div>
            </div>

            : <p><em>{t(errors)}</em></p>;

    return (<Dialog title={props.t(title)} onClose={props.closeViewMandats}>
        <Form render={(formRenderProps) => (<FormElement
            style={{
                width: 670, height:500
            }}>  <div>
                {/*<h4 style={{ marginLeft: 10 , marginTop:-10}}>{t('Mandats')}</h4>*/}

                {contents}

            </div></FormElement>
        )}
        />
    </Dialog>
    );
}
export default withTranslation()(UserRightsDialog);
