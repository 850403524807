import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ConfirmationDialog from './ConfirmationDialog';

const CommandCellConstat = (props) => {

    const { dataItem } = props;
  
    return (
        <td className="k-command-cell">
            <Button icon='download' themeColor={"primary"}
                    onClick={() =>
                    props.download(dataItem)}>
            </Button >
            
            <Button icon='eye' themeColor={"primary"}
                    onClick={() =>
                    props.view(dataItem) }>
            </Button>
          
        </td>
    );
}
export default withTranslation()(CommandCellConstat);
