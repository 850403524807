import { Route, Routes } from 'react-router-dom';
import CustomTabStrip from './CustomTabStrip';
import React from 'react';
import MandatDetail from './mandat/MandatDetail';


function ReactComponent(props) {
    return (
        <CustomTabStrip pages={props.pages} selectedtab={props.selectedtab } >
            <Routes >
                {props.pages.map((item, index) => {
                    return (
                        item.visible && (
                            <Route
                                key={ index}
                                path={item.route}
                                exact={true}
                                element={item.content}>
                            </Route>
                        )
                    );
                })}
                {/*Detail pages*/}
                <Route path="/mandatdetail">
                    <Route path=":id" element={<MandatDetail />} />
                </Route>
            </Routes>
        
        </CustomTabStrip>
    );
}

export default ReactComponent;