const SessionManager = {

    isTokenExpired(token) {
        return JSON.parse(atob(token.split(".")[1])).exp * 1000 < Date.now()
    },
    getToken() {
        const token = sessionStorage.getItem('token');
        if (token === null)
            return null;
        else
            if (this.isTokenExpired(token))
                return null;
            else return token;

    },
    getUserName() {
        const user = sessionStorage.getItem('userName');
        if (user) return user;
        else return null;
    },
    getUserRole() {
        const userRole = sessionStorage.getItem('usersRole');
        if (userRole) return userRole;
        else return null;
    },

    setUserSession(userName, token, userId, usersRole) {
        sessionStorage.setItem('userName', userName);
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
        sessionStorage.setItem('usersRole', usersRole);
    },

    removeUserSession() {
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('usersRole');
    },

    getSelectedTab() {
        return Number(sessionStorage.getItem("selectedtab"));
    },

    setSelectedTab(tabNumber) {
        sessionStorage.setItem("selectedtab", tabNumber);
    },
    getUserId() {
        const user = sessionStorage.getItem('userId');
        if (user) return user;
        else return null;
    },
    setSelectedRoute(route) {
        sessionStorage.setItem("selectedRoute", route);
    },
    getSelectedRoute() {
        return sessionStorage.getItem("selectedRoute");
    },
}

export default SessionManager;