import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { RadioButton } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";

import { Button } from "@progress/kendo-react-buttons";
function TemplatePDFDialog(props) {
    const [title, setTitle] = React.useState("TemplatePDF");
   
    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 30,
        width:300,
        placeholder: null
    };


    const fieldStyleRed = {
        marginBottom: 0,
        marginLeft: 30,
        width: 300,
        placeholder: null,
        color: "Red"
    };


    let contents =
        <Dialog title={i18next.t(title)} onClose={props.cancelPDF}>
            <Form
                initialValues={props.selectedValue}
                onSubmit={props.onSubmit}
                render={() => (
                    <div>
                        <div>
                            <p><b>{props.itemConstat.codeType == "EXT" ? i18next.t("ConstatExterieur") : i18next.t("ConstatInterieur")} {props.itemConstat.description}</b></p>
                        </div>
                        <div>{i18next.t('DateReleve')}
                            <DatePicker name="DateReleve" placeholder={i18next.t('ChooseAdate')}
                                format={"dd.MM.yyyy"} adaptive={true} PopupWidth="400px" width={250} 
                            onChange={props.handleChangeDateReleve}   /></div>
                        <br /> <br />
                      
                        <RadioButton
                            name="group1"
                            value="SignatureMetriX"
                            checked={props.selectedValue === "SignatureMetriX"}
                            label={i18next.t('SignatureMetriX')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignaturesMetriXPropr"
                            checked={props.selectedValue === "SignaturesMetriXPropr"}
                            label={i18next.t('SignaturesMetriXPropr')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignaturesMetriXMandant"
                            checked={props.selectedValue === "SignaturesMetriXMandant"}
                            label={i18next.t('SignaturesMetriXMandant')}
                            onChange={props.handleChange}
                        />
                        
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignaturesMetriXMandantPropr"
                            checked={props.selectedValue === "SignaturesMetriXMandantPropr"}
                            label={i18next.t('SignaturesMetriXMandantPropr')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="23MTX003"
                            checked={props.selectedValue === "23MTX003"}
                            label={i18next.t('23MTX003')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            label={i18next.t("RapportComplet")}
                            checked={props.selectedValue === "RapportComplet"}
                            value='RapportComplet'
                            onChange={props.handleChange}
                        />
                        {props.selectedValue === "RapportComplet" &&

                            props.itemConstatComplement &&
                            <p style={fieldStyle}>
                                <i>{props.itemConstat.codeType == "EXT" ?
                                    i18next.t("ConstatInterieur") :
                                    i18next.t("ConstatExterieur")} {props.itemConstatComplement.description}
                                </i>
                            </p>
                        }

                        {props.selectedValue === "RapportComplet" &&
                            props.itemConstatComplement == null &&
                            <p style={fieldStyleRed}>
                                <i>{props.itemConstat.codeType == "EXT" ?
                                    i18next.t("AucunConstatInterieur") :
                                    i18next.t("AucunConstatExterieur")}
                                </i>
                            </p>
                        }
                        <br /> <br />
                        <Button
                            type={"submit"}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"                            
                            onClick={props.cancelPDF}
                            style={{ marginLeft: 80 }}                        >
                            {i18next.t('Cancel')}
                          
                        </Button>
                        <Button
                            disabled={props.loadingTemplate ==true ||(props.selectedValue === "RapportComplet" && props.itemConstatComplement == null)}
                            type={"submit"}
                            onClick={props.okPDF} 
                            style={{ marginLeft: 10 }}     
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"  >
                            {i18next.t('OK')}
                        </Button>
                        <br /> <br />
                        {props.loadingTemplate == true && <p><em>{i18next.t('GenerationFichierPdfEnCours')}</em></p>}
                        </div>
                          
                )}
            />
        </Dialog>

    return (
        <div>
            {contents}

        </div>

    );
};
export default withTranslation()(TemplatePDFDialog);