import { React, useState, useEffect, cloneElement } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import SessionManager from "../auth/SessionManager";
import { Fade } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import CompleteCommandCell from '../controls/CompleteCommandCell';
import CommandCell from '../controls/CommandCell';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { useParams, useNavigate, Link } from "react-router-dom"
import { getBaseURLAPI } from '../AccessAPI';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loader } from "@progress/kendo-react-indicators";
import { isMobile } from 'react-device-detect';
const initialSort = [{
    field: 'nom',
    dir: 'asc'
}];
const editField = "inEdit";
const editedColor = 'Green';
const defaultColor = 'Black';
const compressImages = false;

import i18next from 'i18next';
import ImgCell  from '../controls/ImgCell';
import Validators from '../controls/Validators';

import StatusCell from '../controls/StatusCell';
function PieceMurs({ t }) {

    let { id } = useParams();
    const txtPasDeFissure = t('PasDeFissure');
    const txtPasDeDegat = t('PasDeDegat');
    const [title, setTitle] = useState(t('CreateMursTitle'));
    const [constatId, setConstatId] = useState('');
    const [zoneId, setZoneId] = useState('');
    const [mandatId, setMandatId] = useState('');
    const [pieceTitle, setPieceTitle] = useState('');
    const [zoneTitle, setZoneTitle] = useState('');
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const [sort, setSort] = useState(initialSort);
    const [murs, setMurs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [imagePath, setImagePath] = useState('');
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const [isApresTravaux, setIsApresTravaux] = useState(false);
    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };
    const reloadFromDb = () => {
        if (reloading)
            setReloading(false);
        else
            setReloading(true);
    };



    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const [editID, setEditID] = useState(null);

    const rowClick = (event) => {
        //setEditID(event.dataItem.id);
    };

    const FissureStatusCell = (props) => (
        <StatusCell
            {...props}
            isApresTravaux={isApresTravaux}
        />
    );

    const DescTextCell = (props) => (
        <td style={{ whiteSpace: 'pre-wrap' }}>
            {props.dataItem.desc}
        </td>
    );

    const remove = async (dataItem) => {
        console.log('remove');
        var result = await MandatManager.deleteMur(dataItem);
        if (result) {
            const newData = [...murs];
            const indexToDelete = newData.findIndex(x => x.id == dataItem.id);
            newData.splice(indexToDelete, 1);
            const newList = murs.filter(x => x.id != dataItem.id);
            setMurs(newList);
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });
        }
    };

    const add = async (dataItem) => {

        console.log('add mur ' + dataItem.isNewItem);

        const hasValidationError = dataItem["NameValid"];
        if (hasValidationError) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t(hasValidationError) });
            return;
        }

        var mursSameName = murs.filter((item) => item.nom == dataItem.nom);
        if (mursSameName && mursSameName.length > 1) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Mur ' + dataItem.nom + ' existe deja!' });

        }
        else {

        
            dataItem.inEdit = false;
            dataItem.isNewItem = false;
            setEditID(null);
            const result = await MandatManager.updateMur(dataItem);
            if (result) {
                let mursNew = murs.map((item) =>
                    item.nom === dataItem.nom
                        ? {
                            ...item,
                            id: result.id,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.nom,
                            isEditedByUser: result.isEditedByUser
                        }
                        : item);
                setMurs(orderBy(mursNew, sort));
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject')+'\n'+t('ReloadingData') });
                reloadFromDb();
            }
        }
       
    };

    const update = async (dataItem) => {
        console.log('update mur');
        var mursSameNom = murs.filter((item) => item.nom == dataItem.nom);
        if (mursSameNom && mursSameNom.length == 1)
        {
            dataItem.inEdit = false;
            setEditID(null);
            const result = await MandatManager.updateMur(dataItem);
            if (result) {
                let mursNew = murs.map((item) =>
                    item.id === dataItem.id
                        ? {
                            ...item,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.nom,
                            isEditedByUser: result.isEditedByUser
                        }
                        : item);

                setMurs(orderBy(mursNew, sort));
                refresh();
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') + '\n' + t('ReloadingData') });
                reloadFromDb();
            }
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Mur ' + dataItem.nom + ' existe deja!' });
        }

    };

    const discard = () => {
        console.log('discard');
        const newData = [...murs];
        newData.splice(0, 1);
        setMurs(newData);
    };

    const cancel = (dataItem) => {
        console.log('cancel');
        dataItem.inEdit = false;
        setEditID(null);
        setMurs(murs.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: false,
                }
                : item
        ));
        reloadFromDb();
        refresh();
    };

    const enterEdit = (dataItem) => {
        console.log('enterEdit');
        let editedData = murs.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: true,
                    NameValid: Validators.nameValidator(item.nom),
                }
                : item
        );

        setEditID(dataItem.id);
        setMurs(editedData);
        refresh();
    };

    const openDetailMur = async (dataItem) => {
        navigateToMur(dataItem);
    };

    const itemChange = (event) => {
        console.log('itemChange');
        const inEditID = event.dataItem.id;
        const field = event.field || "";
        //event.dataItem[field] = event.value;
        const newData = murs.map((item) =>
            item.id === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                    label: item.nom,
                    desc: txtPasDeFissure + "\n" + txtPasDeDegat,
                    NameValid: Validators.nameValidator(event.value)
                }
                : item
        );
        setMurs(newData);
    };

    const closeEdit = (event) => {
        console.log('closeEdit');
        if (event.target === event.currentTarget) {
            setEditID(null);
        }
    };

    const addRecord = async () => {
        console.log('addRecord');
        let maxIdMur = murs.length == 0 ? { id: 0 } : murs.reduce((max, mur) => max.id > mur.id ? max : mur);

        let editedData = murs.filter((item) => item.inEdit == true);
        if (editedData && editedData.length > 0) {
            var mursSameNom = murs.filter((item) => item.nom === editedData[0].nom);
            if (mursSameNom.length > 1) {
                setNotificationState({ ...notificationState, error: true, notificationMessage: 'Mur ' + editedData[0].nom + ' existe deja!' });
                let data = murs.map((item) => (item.label != '' ? {
                    ...item,
                    inEdit: false,
                    idPiece: id,
                    desc: txtPasDeFissure + "\n" + txtPasDeDegat
                } : item)
                );
                setMurs(data);
                return;

            }
            if (editedData[0].position == '')
                return;
        }
        const newRecord = {
            id: maxIdMur.id + 1,
            idPiece: id,
            nom: '',
            inEdit: true,
            isNewItem: true,
            NameValid: Validators.nameValidator('')
        };

        setMurs([newRecord, ...murs]);
        setEditID(newRecord.id);

    };



    const rowRenderMur = (trElement, dataItem, myProp) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: async () => {
                console.log('Double click on ' + dataItem.dataItem.id, myProp)

                openDetailMur(dataItem.dataItem);



            }, 
            //onContextMenu: (e) => {
            //    e.preventDefault()
            //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
            //},
            style: { color: dataItem.dataItem.isEditedByUser ? editedColor : defaultColor }
        };
        return cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const navigateToMur = (dataItem) => {
        navigate("/constatint/" + constatId + "/zone/"+ zoneId+"/piecemurs/" +id + "/mur/" + dataItem.id);
    }


    const mapMurs = (listMurs, loadedFromDb, basePath, setImage, bIsApresTravaux) => {
        console.log(listMurs);
        let mursEdit = listMurs.map((item) =>(
            {
                ...item,
                inEdit: false,
                label: item.nom,
                desc: (item.fissures && item.fissures.length > 0 ? t('Fissures') + ' (' + item.fissures.length + ')' : txtPasDeFissure) + "\n" + (item.degats && item.degats.length > 0 ? t('AutreDegats') + ' (' + item.degats.length + ')' : txtPasDeDegat),
                loadedFromDb: loadedFromDb,
                imagePath: setImage? item.imagePath != null ? basePath + item.imagePath.slice(1):"":item.imagePath,
                fissures: item.fissures.map((itemFissure) => (
                    {
                        ...itemFissure,
                        imagePath: setImage ? itemFissure.imagePath != null && itemFissure.imagePath != 'undefined' && itemFissure.imagePath.length > 0 ? basePath + itemFissure.imagePath.slice(1) : "" : itemFissure.imagePath,
                        timestamp: setImage ? Date.now().toString() : itemFissure.timestamp,
                        disableRemove: bIsApresTravaux ? (itemFissure.idParent ? true : false) : false
                    })),                
                degats: item.degats.map((itemDegat) => (
                    {
                        ...itemDegat,
                        imagePath: setImage ? itemDegat.imagePath != null ? basePath + itemDegat.imagePath.slice(1) : "" : itemDegat.imagePath,
                        timestamp: setImage ? Date.now().toString() : itemDegat.timestamp,
                        disableRemove: bIsApresTravaux ? (itemDegat.idParent ? true : false) : false
                    })),
                timestamp: setImage ? Date.now().toString() : item.timestamp


            }));
     
        return mursEdit;
    };

    const fetchPieceData = async () => {
        console.log('fetch mandat detail');
        let basePath = await getBaseURLAPI();
        MandatManager.getPieceDetail(id, compressImages)
            .then(function (result) {

                if (result) {
                    if (result.idParent) {
                        setIsApresTravaux(true);
                    }
                    setZoneId(result.idZone);
                    setConstatId(result.idConstat);
                    setMandatId(result.idMandat);
                    setPieceTitle(result.nom);
                    setZoneTitle(result.zoneDescription);
                    setMandatTitle(result.mandatDescription);
                    setParcelleTitle(result.parcelleDescription);
                    if (result.imagePath && result.imagePath.length > 1)
                        setImagePath(basePath + result.imagePath.slice(1));

                    let mursEdit = mapMurs(result.murs, true, basePath, true, result.idParent?true:false);
                    console.log(mursEdit);
                    setMurs(orderBy(mursEdit, sort));
                    setLoading(false);
                    setSort(sort);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                console.log(error);
                setErrors(error.message);
            });
    };

    useEffect(() => {

        fetchPieceData();

    }, [reloading]);



    useEffect(() => {
        try {
           
            console.log(window.innerWidth);

            setIsSmallScreen(window.innerWidth < 800 ? true : false);

        } catch (e) { console.log(e); }

    }, []);

    const expandChange = (event) => {
        let mursNew = murs.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setMurs(mursNew);
    };

    const MurCommandCell = (props) => (
        <CompleteCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            detail={openDetailMur}
            canOpenDetail={true} //{props.dataItem && props.dataItem.loadedFromDb}
            title={t('Mur')}
            canEdit={!isApresTravaux}
            canDelete={!isApresTravaux}
        />
    );


    const ImageCell = (props) => (
        <ImgCell
            {...props}

        />
    );

    const ListFissuresDetailComponent = (props) => {

        const navigate = useNavigate();
        const rowRenderFissure = (trElement, dataItem, myProp) => {
            const nullImage = dataItem.dataItem.imagePath == null || dataItem.dataItem.imagePath == "undefined"|| dataItem.dataItem.imagePath.length==0;
            const defaut = {
                backgroundColor: "#fff",
            };
            const red = {
                backgroundColor: "rgb(243, 23, 0, 0.32)",
            };
            

            const trProps = {
                ...trElement.props,
                onDoubleClick: () => {
                    if (!dataItem.dataItem.inEdit) {
                        console.log('Double click on ' + dataItem.dataItem.id, myProp)
                        openDetailFissure(dataItem.dataItem);
                    }
                },
                //onContextMenu: (e) => {
                //    e.preventDefault()
                //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
                //},
                style: nullImage ? red : defaut,
            };
            return cloneElement(trElement, { ...trProps }, trElement.props.children);
        }

        const rowRenderDegat = (trElement, dataItem, myProp) => {
            const nullImage = dataItem.dataItem.imagePath == null || dataItem.dataItem.imagePath == "undefined" || dataItem.dataItem.imagePath.length == 0;
            const defaut = {
                backgroundColor: "#fff",
            };
            const red = {
                backgroundColor: "rgb(243, 23, 0, 0.32)",
            };


            const trProps = {
                ...trElement.props,
                onDoubleClick: () => {
                    if (!dataItem.dataItem.inEdit) {
                        console.log('Double click on ' + dataItem.dataItem.id, myProp)
                        openDetailDegat(dataItem.dataItem);
                    }
                },
               
                style: nullImage ? red : defaut,
            };
            return cloneElement(trElement, { ...trProps }, trElement.props.children);
        }


        const [fissures, setFissures] = useState(props.dataItem.fissures);
        const [degats, setDegats] = useState(props.dataItem.degats);

        const FissureCommandCell = (props) => (
            <CommandCell
                {...props}
                remove={removeFissure}
                detail={openDetailFissure}
                canEdit={false}
                title={t('Fissure')}
            />
        );
        const DegatCommandCell = (props) => (
            <CommandCell
                {...props}
                remove={removeDegat}
                detail={openDetailDegat}
                canEdit={false}
                title={t('Degat')}
            />
        );
        const removeFissure = async (dataItemFissure) => {
            var result = await MandatManager.deleteFissure(dataItemFissure);
            if (result) {
                const newFissures = [...fissures];
                const indexToDelete = newFissures.findIndex(x => x.id == dataItemFissure.id);
                newFissures.splice(indexToDelete, 1);
                const newList = fissures.filter(x => x.id != dataItemFissure.id);
                setFissures(newList);

                let newMurs = murs.map((itemMur) => {

                    if (dataItemFissure.idMur === itemMur.id) {
                        itemMur.fissures = newList;
                    }
                    return itemMur;
                });
                let mursEdit = mapMurs(newMurs, false,'', false);
                setMurs(mursEdit);

                setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });

            }
        };

        const openDetailFissure = async (dataItem) => {
            navigateToFissure(dataItem);
           
        }

        const removeDegat = async (dataItemDegat) => {
            var result = await MandatManager.deleteDegat(dataItemDegat);
            if (result) {
                const newDegats = [...degats];
                const indexToDelete = newDegats.findIndex(x => x.id == dataItemDegat.id);
                newDegats.splice(indexToDelete, 1);
                const newList = degats.filter(x => x.id != dataItemDegat.id);
                setDegats(newList);

                let newMurs = murs.map((itemMur) => {

                    if (dataItemDegat.idMur === itemMur.id) {
                        itemMur.degats = newList;
                    }
                    return itemMur;
                });
                let mursEdit = mapMurs(newMurs, false, '', false);
                setMurs(mursEdit);

                setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });

            }
        };

        const openDetailDegat = async (dataItem) => {
            navigateToDegat(dataItem);

        }
        const navigateToFissure = (dataItem) => {
            navigate("/constatint/" + constatId + "/zone/" + zoneId + "/piecemurs/" + id + "/mur/" + dataItem.idMur + "/fissure/" +  dataItem.id);
        }
        const navigateToDegat = (dataItem) => {
            navigate("/constatint/" + constatId + "/zone/" + zoneId + "/piecemurs/" + id + "/mur/" + dataItem.idMur + "/autredegat/" + dataItem.id);
        }
        return (
            <div>
               <h3>{t('Fissures')}</h3>
                <Grid data={fissures}
                rowRender={(trElement, dataItem) => rowRenderFissure(trElement, dataItem, { myProp: "test" })}
                resizable={true}            >
                    {/*<Column field="id" title="No" />*/}

                    <Column cell={FissureStatusCell} width={5}></Column>
                    <Column cell={ImageCell} width={50} ></Column> {/*title={i18next.t('Fissure')}*/}
                    {!isMobile && <Column field="orientationText" title={i18next.t('Orientation')} />}
               
                <Column cell={FissureCommandCell} width={100} />
            </Grid>
                <h3>{t('AutreDegats')}</h3>
             <Grid data={degats}
                    rowRender={(trElement, dataItem) => rowRenderDegat(trElement, dataItem, { myProp: "degat" })}
                resizable={true}            >
                    {/*<Column field="id" title="No" />*/}
                <Column cell={FissureStatusCell} width={5}></Column>
                <Column cell={ImageCell} width={50}></Column>
                <Column field="description" title={i18next.t('Description')} />  {/*title={i18next.t('Degat')}*/}
                <Column cell={DegatCommandCell} width={100} />
                </Grid>
            </div>
        );

        return (
            <div
                style={{
                    height: "50px",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                    }}
                >
                  </div>
            </div>
        );
    };

    let contents = loading
        ? <div>
            <p><em>{t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
            {/*<Loader type="infinite-spinner" size="large" themeColor="success"></Loader>*/}
        </div>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                        whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
                    <img src={imagePath } style={{ maxHeight: 200, maxWidth: 200 }} />
                    <Grid
                        name="MursGrid"
                        
                        data={murs.map((item) => ({
                            ...item,
                            inEdit: item.id === editID,
                        }))}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setMurs(orderBy(murs, e.sort));
                                refresh();

                            }
                        }

                        editField="inEdit"
                        onRowClick={rowClick}
                        onItemChange={itemChange}
                        detail={ListFissuresDetailComponent}

                        expandField="expanded"
                        onExpandChange={expandChange}
                        rowRender={(trElement, dataItem) => rowRenderMur(trElement, dataItem, { myProp: "test" })}
                        resizable={true}
                    >
                        <GridToolbar>
                            <div onClick={closeEdit}>
                                <button
                                    title={t('Add')}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={addRecord} disabled={isApresTravaux}
                                >
                                    {t('Add')}
                                </button>
                            </div>
                         

                        </GridToolbar>

                        <Column field="nom" filterable={false} title={t('M')} width={65} />
                        <Column cell={ImageCell} width={50}></Column>

                       <Column field="desc" filterable={false} title={t('Degats')} cell={DescTextCell} editable={false} />

                        {/*{!isSmallScreen && <Column field="desc" filterable={false} title={t('Degats')} cell={DescTextCell}  editable={false} />}*/}
                        <Column cell={MurCommandCell} width="150px" />

                    </Grid>
                </div>

            </div>
            : <p><em>{t(errors)}</em></p>;


    return (
        <div>

            <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{title}</h4>

            <Link to={'/constatint/' + constatId}>
                <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
            </Link>
            <Link to={'/mandatdetail/' + mandatId}>
                <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
            </Link>
            <Link to={'/constatint/' + constatId + '/zone/' + zoneId}>
                <p style={{ marginLeft: 10 }}><i>{zoneTitle} </i></p>
            </Link>
            <p style={{ marginLeft: 10 }}><i><b>{pieceTitle}</b> </i></p>
            {/*<Link to={'/constatint/' + constatId + '/zone/' + zoneId + '/piecedetail/' + id}>*/}
            {/*    <p style={{ marginLeft: 10 }}><i>{pieceTitle} </i></p>*/}
            {/*</Link>*/}
            {contents}
            
        </div>
    );
}
export default withTranslation()(PieceMurs);
