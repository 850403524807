import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";


const ImgCell = (props) => {

    const compressImages = false;

    if (compressImages) {
        if (props.dataItem.thumbnailImageString != null && props.dataItem.thumbnailImageString.length > 0)
            return (

                <td> <LazyLoadImage src={"data:image /png; base64," + props.dataItem.thumbnailImageString}
                    width={30} height={30}

                />
                   
                </td>
            );
        else
            return (<td><span></span></td>);
    }
    else {
        if (props.dataItem.imagePath != null && props.dataItem.imagePath.length > 0)
            return (

                <td> <LazyLoadImage src={props.dataItem.imagePath + "?" + props.dataItem.timestamp}
                    width={30} height={30}

                />
                   
                </td>
            );
        else
            return (<td><span></span></td>);

    }
};
export default ImgCell;