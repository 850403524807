import * as React from "react";

const CustomCell = (props) => {
    // Destructure the data item and field name
    const { dataItem, field } = props;

    // Determine style based on the `status` property
    const cellStyle = dataItem.differences?.some((property) => property.propertyName.toLowerCase() === field.toLowerCase()) ? { backgroundColor: 'lightgreen' } : { backgroundColor: '' };

   
   // users.some((user) => user.name === userNameToCheck)
    // Render the cell with the custom style
    return (
     /*   <td style={cellStyle}>*/
        <td >
            {dataItem[field]} {/* Render the value */}
        </td>
    );
}
export default (CustomCell);