import { React, useState, useEffect } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar, GridHeader } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import SessionManager from "../auth/SessionManager";
import { Fade } from "@progress/kendo-react-animation";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { filterBy } from "@progress/kendo-data-query";
import { useParams, useNavigate } from "react-router-dom";
import { getBaseURL, getBaseURLAPI } from "../AccessAPI";

import Constats from "./Constats";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "fullTextSearch",
            operator: "contains",
            value: "",
        },
    ],
};
import i18next from 'i18next';




const initialSort = [{
    field: 'numero',
    dir: 'asc'
}];

const filterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ]

};



const ParcelleDetailComponent = (props) => {
    const dataItem = props.dataItem;  
    const [baseUrl, setBaseUrl] = useState('');
   

    useEffect(() => {
        const fetchData = async () => {
            setBaseUrl(await getBaseURLAPI());
            //let userRole = SessionManager.getUserRole();
            //if (userRole == 'Administrateur')
            //    setCanViewConstat(true);
            //else
            //    setCanViewConstat(false);
        }
        fetchData();
    }, []);

   

    return (<div key={dataItem.id}>
        <table key={dataItem.id + "1"} className="tableMandat" >
                <tbody>
                  <tr>
                   <td style={{ width: `100%` }}>

                        <section>
                            {baseUrl && <img src={baseUrl + props.dataItem.parcelle.orthophotoPath.slice(1)} className="photo"></img>}

                        </section>
                            <section>
                                {dataItem.parcelle.proprietaires.map(personne => <p>{personne?.firstName} {personne?.lastName}</p>)}
                                <p>
                                   {dataItem.parcelle.habitation}
                                </p>
                                <p>
                                   {dataItem.parcelle.bienfond}
                                </p>
                            </section>
                        </td>
                        <td style={{ width: `50%` }}>
                          
                        </td>
                       
                </tr>
             
              
                </tbody>
            </table>

        <table className="tableMandat">
            <tbody>
                <tr>
                    <td style={{ width: `100%` }}>
                       

                        <Constats mandat={dataItem} ></Constats>
                               
                    </td>

                </tr>
            </tbody>
        </table>

        </div>
        )
    




    
    
};


function MandatDetail({ t }) {
    let { id } = useParams();
    const [relMandatParcelles, setRelMandatParcelles] = useState([]);
    const [sort, setSort] = useState(initialSort);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [errors, setErrors] = useState('');
    const [title, setTitle] = useState('');
    
   
    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };
   
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const { success, error, warning, info, none, notificationMessage } = notificationState;
    const onFilterChange = (event) => {
        var filter = { logic: 'or', filters: [] };
        filter.filters.push({
            field: "fullTextSearch",
            operator: 'contains',
            value: event.target.value
        })
        setRelMandatParcelles(relMandatParcelles);
        setFilter(filter);
    };

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetch mandat detail');
            
            MandatManager.getMandatDetail(id)
                .then(function (result) {

                    if (result) {
                        setTitle(result.fullTextSearch);
                        setRelMandatParcelles(result.relMandatParcelles);
                        setLoading(false);
                        setSort(sort);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };

       
        fetchData();

    }, []);

    useEffect(() => {
        setRelMandatParcelles(relMandatParcelles);

    }, [updating]);

    const expandChange = (event) => {
        let parcellesNew = relMandatParcelles.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setRelMandatParcelles(parcellesNew);
    };
    let contents = loading
        ? <p><em>{t('Loading')} </em></p>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
               
                    <Grid
                        name="ParcellesGrid"
                        data={filterBy(orderBy(relMandatParcelles, sort), filter)}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setRelMandatParcelles(orderBy(relMandatParcelles, e.sort));
                                refresh();

                            }
                        }
                        filterable={true}
                        filter={filter}
                        filterOperators={filterOperators}
                        onFilterChange={(e) => {
                            setFilter(e.filter);
                        }}
                        detail={ParcelleDetailComponent}

                        expandField="expanded"
                        onExpandChange={expandChange}
                        resizable={true}
                    >
                        <GridToolbar>
                          

                            <input id='filter' onChange={onFilterChange} placeholder={t('Search')}
                                style={{
                                    marginLeft: 10, width: "250px", height: "25px"
                                }} />

                        </GridToolbar>

                        <Column field="parcelle.numero" filterable={false} title={t('Parcelle')} width={100} className="column-wrap"/>
                        <Column field="parcelle.adresse" filterable={false} title={t('Adresse')} />


                    </Grid>

                </div>

            </div>

            : <p><em>{t(errors)}</em></p>;

    return (
        <div>
            <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom:5 }}>{title}</h4>

            {contents}

        </div>
    );
}
export default withTranslation()(MandatDetail);
