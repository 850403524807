import React from 'react';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button } from "@progress/kendo-react-buttons";
import SessionManager from './auth/SessionManager';
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import ChangePasswordDialog from './user/ChangePasswordDialog';
import UserManager from './user/UserManager';

function AppStatusBar(props) {

    const anchor = React.useRef(null);
    const [isComponentVisible, setIsComponentVisible] = React.useState(false);
    const [isChangePasswordVisible, setIsChangePasswordVisible] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const btnRef = React.useRef();
    const btnChangeRef = React.useRef();
    const popupRef = React.useRef();
    const handleClickOutside = event => {
        if (isComponentVisible) {
            if (btnRef.current && btnRef.current.contains(event.target)) {                
                props.onLogOffClicked();
            }
            if (btnChangeRef.current && btnChangeRef.current.contains(event.target)) {
                //props.onChangePasswordClicked();
                setIsChangePasswordVisible(true);
            }
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsComponentVisible(false);
            }
        }
    };
    React.useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }); 
    const showPopupAccount = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    //const passwordChange = (e) => {



    //}

    const handleChangePassword = async (userPasswordChange) =>
    {
        userPasswordChange.UserName = SessionManager.getUserName();
        const resultPasswordChange = await UserManager.passwordChange(userPasswordChange);
        if (resultPasswordChange.succeeded)
        {
            setIsChangePasswordVisible(false);
        }
        else
        {
            setErrorMessage(resultPasswordChange.errors[0].description);
        }
        
    };

   

    const handleCancelChangePassword = () => {
        setErrorMessage("");
        setIsChangePasswordVisible(false);
    };
    
    return (
        <AppBar style={{marginTop:0, marginBottom:-10}} >
           
            {/*<AppBarSpacer*/}
            {/*    style={{*/}
            {/*        width: 4,*/}
            {/*    }}*/}
            {/*/>*/}

            <AppBarSection >
                <h3 style={{ marginTop: -10, marginBottom:-10, display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'bottom' }} className="title">{i18next.t('App_Title')} </h3>
            </AppBarSection>

            {/*<AppBarSpacer*/}
            {/*    style={{*/}
            {/*        width: 32,*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<AppBarSpacer />*/}
         
            {/*<AppBarSection style={{ marginLeft: 10 }}>*/}
            {/*    <span className="k-appbar-separator" />*/}
            {/*</AppBarSection> {SessionManager.getUserName()}*/}
          
          
            {/*<AppBarSection></AppBarSection>*/}
            <AppBarSection className="actions" style={{marginBottom:0, width:20} }>
                

                <BadgeContainer className="tooltip">
                    <Button className="roundButton" onClick={showPopupAccount} ref={anchor }>
                        <span ref={popupRef}  className="k-icon k-i-user" />
                        {/*<span class="tooltiptext">{props.t('LogOff')} </span>*/}
                    </Button>

                </BadgeContainer>
                <div
                    className="wrapper"
                    style={{
                        width: 150,

                    }}   >

                {isComponentVisible && 
                        
                            <Popover  
                                className="popover"
                                show={isComponentVisible}
                                anchor={anchor.current && anchor.current.element}
                                position={"bottom"}
                                title={i18next.t('MyAccount')} 
                            onClick={() => setIsComponentVisible(true)}>

                            <div style={{ width: 240}}>
                            <div >
                                <p>{i18next.t('Login')}: <b>{SessionManager.getUserName()}</b></p>
                       
                                <p>{i18next.t('Role')}: <b>{SessionManager.getUserRole()}</b></p>
                            </div>
                       
                                <PopoverActionsBar>
                                    <div>
                                    <div ref={btnRef} >
                                        <Button icon="logout" onClick={props.onLogOffClicked} themeColor={"primary"} >
                                                {i18next.t('SignOut')}
                                        </Button>
                                        </div>
                                    <div ref={btnChangeRef} >
                                    <p>
                                          <Button icon="password" onClick={props.onChangePasswordClicked} themeColor={"primary"} >
                                                    {i18next.t('ChangePassword')}
                                        </Button>
                                        </p>
                                        </div>
                                    </div>
                                </PopoverActionsBar>
                                </div>
                            </Popover>
                       
                    } 


                    {isChangePasswordVisible && (
                        <ChangePasswordDialog
                            cancelEdit={handleCancelChangePassword}
                            onSubmit={handleChangePassword}
                            errorMessage={errorMessage} 

                        />
                    )}
                </div></AppBarSection>
        </AppBar>


        
    );
}


export default withTranslation()(AppStatusBar);
