import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function ConfirmationDialog(props) {
    return (
        <Dialog title={props.Title} onClose={props.onClose} style={{ zIndex: '11000' }}>

            {props.Message}
            <div className="k-form-buttons" style={{ justifyContent: 'center' }}>
                <Button themeColor={"primary"} onClick={props.onConfirm}>
                    {i18next.t('Oui')}
                </Button>
                <Button onClick={props.onClose}>
                    {i18next.t('Non')}
                </Button>
            </div>

        </Dialog>
    );
}

export default withTranslation()(ConfirmationDialog);