import SessionManager from "./auth/SessionManager";
const BASE_URL = 'https://metrixconstats.rwb.ch';//'https://mobile.newis.ch/appcal'; //  'http://localhost:7025';// "https://srv-az-metrixconstats.westeurope.cloudapp.azure.com/metrixconstats"//
const BASE_URL_API = 'https://metrixconstats.rwb.ch';//'https://mobile.newis.ch/appcal'; //  'http://localhost:4000'; //"https://srv-az-metrixconstats.westeurope.cloudapp.azure.com/metrixconstats" //

export async function getData(endPoint) {    
    endPoint = BASE_URL + endPoint;
    let token = SessionManager.getToken();

    let payload = {
        method: 'GET',
        headers: {
            "access-control-allow-origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }
    return fetch(endPoint, payload)
        .then(function (response) {
            console.log(response);

            if (!response.ok)
            {
                if(response.status == 403)
                    throw Error("Forbidden");
                else
                    throw Error(response.statusText);
            }
            return response.json();
        }).then(function (result) {
            return result;
        }).catch(function (error) {
            console.log(error);
            //return error.message;
            throw error;
        });
}

export function postDataForLogin(type, userData) {
    type = BASE_URL +  type;
    return fetch(type, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(userData)
    }).then(function (response) {
        return response.json();
    }).then(function (result) {
        return result;
    }).catch(function (error) {
        console.log(error);
    });
}

export function postData(endPoint, inputObj) {
    endPoint = BASE_URL + endPoint;
    let token = SessionManager.getToken();
    let payload = {
        method: 'POST',
        headers: {
            "access-control-allow-origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputObj)

    }
    return fetch(endPoint, payload)
        .then(function (response) {
            return response.json();
        }).then(function (result) {
            return result;
        }).catch(function (error) {
            console.log(error);
        });
}

export function deleteData(endPoint) {
    let token = SessionManager.getToken();
    let payload = {
        method: 'DELETE',
        headers: {
            "access-control-allow-origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }
    return fetch(BASE_URL + endPoint, payload)
        .then(function (response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(function (result) {
            return result;
        }).catch(function (error) {
            console.log(error);
        });
}

export function removeData(endPoint, inputObj) {
    let token = SessionManager.getToken();
    let payload = {
        method: 'DELETE',
        headers: {
            "access-control-allow-origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(inputObj)
    }
    return fetch(BASE_URL + endPoint, payload)
        .then(function (response) {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(function (result) {
            return result;
        }).catch(function (error) {
            console.log(error);
        });
}


export function putData(endPoint, obj) {
    endPoint = BASE_URL + endPoint;
    let token = SessionManager.getToken();
    let payload = {
        method: 'PUT',
        headers: {
            "access-control-allow-origin": "*",
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(obj)

    }
    return fetch(/*BASE_URL +*/ endPoint, payload)
        .then(function (response) {
            return response.json();
        }).then(function (result) {
            return result;
        }).catch(function (error) {
            console.log(error);
        });
}

export async function  getBaseURL()
{
    return BASE_URL; 
}

export async function getBaseURLAPI() {
    return BASE_URL_API;
}