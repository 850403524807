import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ValidationInput from '../controls/ValidationInput';




function ChangePasswordDialog(props) {
    const [title, setTitle] = React.useState("ChangePassword");
    const [password, setPassword] = React.useState("");



    const confirmPasswordValidator = (value) => {

        if (password == value)
            return "";
        else
            return i18next.t('ErrorPasswordNotMatch');

    }

    const passwordValidator = (value) => {

        if (value == null)
            return i18next.t('ErrorPasswordNull');
        else if (value.length < 6)
            return i18next.t('ErrorPasswordLength');
        else
            return "";

    };




    const handlePasswordChange = (event) => {
        if (event.target.name === "password") {
            setPassword(event.target.value);
        }
    };

    let contents =
        <Dialog title={i18next.t(title)} onClose={props.cancelEdit}>
            <Form
                onSubmit={props.onSubmit}
                initialValues={props.item}
                render={(formRenderProps) => (
                    <FormElement
                        style={{
                            width: 670,
                        }}>
                        <fieldset className={"k-form-fieldset"}
                            style={{
                                margin: 10, width: "650px"
                            }}
                        >

                            <div>
                                <div className="mb-3">
                                    <Field
                                        name={"passwordOld"}
                                        component={ValidationInput}
                                        type={"password"}
                                        label={i18next.t('PasswordOld')}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Field
                                        name={"password"}
                                        component={ValidationInput}
                                        type={"password"}
                                        label={i18next.t('PasswordNew')}
                                        validator={passwordValidator}
                                        onMouseLeave={handlePasswordChange}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Field
                                        name={"confirmationPassword"}
                                        component={ValidationInput}
                                        type={"password"}
                                        label={i18next.t('ConfirmationPaswword')}
                                        validator={confirmPasswordValidator}
                                    />
                                </div>
                            </div>





                        </fieldset>
                        <div className="k-form-buttons" style={{
                            display: "flex", justifyContent: 'flex-end',
                            margin: 10, width: "580px", float: "right", color: 'red'
                        }}>
                            {i18next.t(props.errorMessage)}
                        </div>
                        <div className="k-form-buttons" style={{
                            display: "flex", justifyContent: 'flex-end',
                            margin: 10, width: "580px", float: "right"
                        }}>


                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                disabled={!formRenderProps.allowSubmit}  >
                                {i18next.t('Update')}
                            </button>
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={props.cancelEdit} >
                                {i18next.t('Cancel')}
                            </button>


                        </div>

                    </FormElement>
                )}
            />
        </Dialog>

    return (
        <div>
            {contents}

        </div>

    );
};
export default withTranslation()(ChangePasswordDialog);
