import { React, useState, useRef, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import i18next from 'i18next';
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { getBaseURL } from "../AccessAPI";
import 'react-html5-camera-photo/build/css/index.css';
import { Button } from "@progress/kendo-react-buttons";
import Webcam from 'react-webcam';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, TextBox, TextArea } from "@progress/kendo-react-inputs";
import { isMobile } from 'react-device-detect';
import { Loader } from "@progress/kendo-react-indicators";
import {
    FloatingLabel,
    Label,
    Hint,
    Error,
} from "@progress/kendo-react-labels";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
const videoConstraints = {
    width: 600,
    height: 600,
    facingMode: 'environment', 
}

const Fissure = ({ params }) => {
    const navigate = useNavigate();
    const [updating, setUpdating] = useState(false);
    const [title, setTitle] = useState(i18next.t('CreateFissureTitle'));
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [fissureTitle, setFissureTitle] = useState('');
    const [orientationList, setOrientationList] = useState([]);
    const [niveauList, setNiveauList] = useState([]);
    const [largeurList, setLargeurList] = useState([]);
    //const [positionList, setPositionList] = useState([]);
    const [picture, setPicture] = useState('');
    const [pictureParent, setPictureParent] = useState('');
    const [leftId, setLeftId] = useState(null);
    const [centerId, setCenterId] = useState(null);
    const [rightId, setRightId] = useState(null);
    const [overwriteImage, setOverwriteImage] = useState(false);

    const [isModifiedApresTravaux, setIsModifiedApresTravaux] = useState(false);


    const webcamRef = useRef(null)
    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot({ width: 3840, height: 3840 });
        console.log(pictureSrc);
        setOverwriteImage(true);
        setPicture(pictureSrc);
    })
    const [hasFissures, setHasFissures] = useState(false);
    const [currentOrientation, setCurrentOrientation] = useState(null);
    const [currentLargeur, setCurrentLargeur] = useState(null);
    const [currentNiveau, setCurrentNiveau] = useState(null);
    const [currentFissure, setCurrentFissure] = useState(null);
    const [originalFissure, setOriginalFissure] = useState(null);
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });
    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const onChangeOrientation = (e) => {
       
        setCurrentFissure(existingValues => ({
            ...existingValues,
            idOrientation: e.target.value.id,
        }));
        setCurrentOrientation(e.target.value);
    };
    const onChangeLargeur = (e) => {
       
        setCurrentFissure(existingValues => ({
            ...existingValues,
            idLargeur: e.target.value.id,
        }));
        setCurrentLargeur(e.target.value);
    };
    const onChangeNiveau = (e) => {
      
        setCurrentFissure(existingValues => ({
            ...existingValues,
            idNiveau: e.target.value.id,
        }));
        setCurrentNiveau(e.target.value);
    };
    const onCloseNiveau = (e) => {

        setCurrentNiveau(null);
    };
    const onCloseLargeur = (e) => {

        setCurrentLargeur(null);
    };
    const onCloseOrientation = (e) => {

        setCurrentOrientation(null);
    };
    const setCurrentPositionFissure = useCallback(
        (val) => {
            setCurrentFissure(existingValues => ({
                ...existingValues,
                idPosition: val,
            }))
        },
        [currentFissure?.position]
    );
    const handleChangeLongueur = useCallback(
        (e) => {
            if (e.value <= 0) return;
            setCurrentFissure(existingValues => ({
                ...existingValues,
                longueur: e.value,
            }))
        },
        [currentFissure?.longueur]
    );
    const handleChangeDescription = useCallback(
        (e) => {
            setCurrentFissure(existingValues => ({
                ...existingValues,
                description: e.value,
            }))
        },
        [currentFissure?.description]
    );

    const handleFileInput = useRef(null);

    const handleClick = () => {
        handleFileInput.current.click();
    };

    const handleImageChange = (event) => {

        try {
            let file = event.target.files[0];

            //setImageObject({
            //    imagePreview: URL.createObjectURL(file),
            //    imageFile: file,
            //});

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let pictureFromTempFile = reader.result.toString();
                setPicture(pictureFromTempFile);
                setOverwriteImage(true);
                //let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                //if ((encoded.length % 4) > 0) {
                //    encoded += '='.repeat(4 - (encoded.length % 4));
                //}
                //resolve(encoded);
            };
            reader.onerror = (error) => {
                console.log(error);
                setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('Error') });
            };
        }
        catch (e) {
            console.log(e);
            setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('Error') });
        }
    };

    const saveFissure = async (navigateToParent) => {
        console.log('Saving fissure');
        const dataItemFissure = {
            idFacade: currentFissure.idFacade?.toString(),
            idMur: currentFissure.idMur?.toString(),
            id: currentFissure.id?.toString(),
            imageString: picture,
            idNiveau: currentFissure.idNiveau?.toString(),
            idLargeur: currentFissure.idLargeur.toString(),
            longueur: currentFissure.longueur.toString(),
            idOrientation: currentFissure.idOrientation.toString(),
            idPosition: currentFissure.idPosition?.toString(),
            description: currentFissure.description,
            overwriteImage: overwriteImage,
            idParent:currentFissure.idParent
        }
        const result = await MandatManager.updateFissure(dataItemFissure);

        
        if (result && result.errors == null) {
            setNotificationState({ ...notificationState, success: true, notificationMessage: i18next.t('SuccessSavingObject') });
            if (navigateToParent) {
                if (result.idFacade) {
                    navigateToFacade();
                    refresh();
                }
                else if (result.idMur) {
                    navigateToMur();
                    refresh();
                }
            }
            else {
                await navigateToFissure();
                refresh();
            }
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('ErrorSavingObject') });
        }
    };


    const validateFissure = async (saveChanges) => {
        console.log('Validating fissure');
       
        const dataItemFissure = saveChanges ? {
            idFacade: currentFissure.idFacade?.toString(),
            idMur: currentFissure.idMur?.toString(),
            id: currentFissure.id?.toString(),
            imageString: picture,
            idNiveau: currentFissure.idNiveau?.toString(),
            idLargeur: currentFissure.idLargeur.toString(),
            longueur: currentFissure.longueur.toString(),
            idOrientation: currentFissure.idOrientation.toString(),
            idPosition: currentFissure.idPosition?.toString(),
            description: currentFissure.description,
            overwriteImage: overwriteImage,
            idParent: currentFissure.idParent,
            validated: true
        } :
            {
                //save only Validated and Photo fields
                idFacade: originalFissure.idFacade?.toString(),
                idMur: originalFissure.idMur?.toString(),
                id: originalFissure.id?.toString(),
                imageString: picture,
                idNiveau: originalFissure.idNiveau?.toString(),
                idLargeur: originalFissure.idLargeur.toString(),
                longueur: originalFissure.longueur.toString(),
                idOrientation: originalFissure.idOrientation.toString(),
                idPosition: originalFissure.idPosition?.toString(),
                description: originalFissure.description,
                overwriteImage: overwriteImage,
                idParent: originalFissure.idParent,
                validated: true
            }

        const result = await MandatManager.updateFissure(dataItemFissure);


        if (result && result.errors == null) {
            setNotificationState({ ...notificationState, success: true, notificationMessage: i18next.t('SuccessSavingObject') });
            
            if (result.idFacade) {
                navigateToConstatExt();
                refresh();
            }
            else if (result.idMur) {
                navigateToConstatInt();
                refresh();
            }

        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('ErrorSavingObject') });
        }
    };
    const navigateToFacade= () => {
        navigate("/constat/" + params.constatid + "/facade/" + currentFissure.idFacade);
    }
    const navigateToMur = () => {
        navigate("/constatint/" + params.constatid + "/zone/" + params.zoneid + "/piecemurs/" + params.pieceid + "/mur/" + currentFissure.idMur );
    }
    const navigateToConstatInt = () => {
        navigate("/constatint/" + params.constatid + "/zone/" + params.zoneid + "/piecemurs/" + params.pieceid);
    }
    const navigateToConstatExt = () => {
        navigate("/constat/" + params.constatid  );
    }
    const navigateToFissure = async () => {
        console.log('navigateToFissure');

        if (params.numero == "create") {
           
            window.location.reload(false);
        }
        else {
            if (currentFissure.idFacade) {
                setCurrentOrientation(null);

                navigate("/constat/" + params.constatid + "/facade/" + params.facadeid + "/fissure/create");
            } else {
                setCurrentOrientation(null);
                navigate("/constatint/" + params.constatid + "/zone/" + params.zoneid + "/piecemurs/" + params.pieceid + "/mur/" + params.murid + "/fissure/create");
            }
        }
      
    }
    const saveFissureAndAddFissure = async () => {
        console.log('saveFissureAndAddFissure');
        saveFissure(false);
    };

    const saveFissureAndReturn = async () => {
        console.log('saveFissureAndReturn');
        saveFissure(true);
            
    };
    

    const validate = async () => {
        validateFissure(isModifiedApresTravaux);
    }

    const refresh = () => {
        console.log('refresh');
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    }
    useEffect(() => {
        const fetchOrientationType = async () => {
            MandatManager.getCatalogValuesList('orientationtype')
                .then(function (result) {
                    if (result) {
                        setOrientationList(result);
                    }
                })
                .catch(function (error) {
                    
                    console.log(error);
                });


        };
        const fetchPositionType = async () => {
            MandatManager.getCatalogValuesList('positiontype')
                .then(function (result) {
                    if (result) {
                       // setPositionList(result);
                        setLeftId(result.find(x => x.code == 'L').id);
                        setCenterId(result.find(x => x.code == 'C').id);
                        setRightId(result.find(x => x.code == 'R').id);
                    }
                })
                .catch(function (error) {
                   
                    console.log(error);
                });


        };


        const fetchNiveauType = async () => {
            MandatManager.getCatalogValuesList('niveautype')
                .then(function (result) {
                    if (result) {
                        setNiveauList(result);
                    }
                })
                .catch(function (error) {
               
                    console.log(error);
                });


        };

        const fetchLargeurType = async () => {
            MandatManager.getCatalogValuesList('largeurtype')
                .then(function (result) {
                    if (result) {
                        setLargeurList(result);
                    }
                })
                .catch(function (error) {

                    console.log(error);
                });


        };

        const fetchFissure = async () => {
            console.log('fetch fissure detail');
            let baseUrl = await getBaseURL();
            if (params.numero == "create") {

                 //new fissure

                if (params.facadeid)
                {
                    setFissureTitle(title);

                    let newFissure = { idFacade: params.facadeid };
                    setCurrentFissure(newFissure);

                    setOverwriteImage(false);
                    setPicture('');
                    setLoading(false);


                    
                }
                else if (params.murid) {
                    setFissureTitle(title);

                    let newFissure = { idMur: params.murid, idZone: params.zoneid, idPiece:params.pieceid };
                    setCurrentFissure(newFissure);

                    setOverwriteImage(false);
                    setPicture('');
                    setLoading(false);

                   
                }
               
            }
            else {
                MandatManager.getFissureDetail(params.numero)
                    .then(function (result) {
                        if (result) {
                            //setMandatTitle(result.mandatDescription);
                            //setParcelleTitle(result.parcelleDescription);
                         
                            setFissureTitle(title + " " + result.id);
                            setCurrentFissure(result);
                            setOriginalFissure(result);
                            if (result.imageString) {
                                setPicture('data:image/png;base64,' + result.imageString);
                                setOverwriteImage(false);
                            }
                            else {

                                var imageUrl = '';
                                if (result.imagePath) {
                                    // Create a timestamp
                                    var timestamp = new Date().getTime();

                                    //remove first character='.'
                                    imageUrl = baseUrl + result.imagePath.slice(1) + "?t=" + timestamp;
                                }
                                setOverwriteImage(false);
                                setPicture(imageUrl);

                            }
                            if (result.fissureParent)
                                if (result.fissureParent?.imageString) {
                                    setPictureParent('data:image/png;base64,' + result.fissureParent?.imageString);
                                }
                                else
                                {
                                    if (result.fissureParent?.imagePath)
                                    {
                                        var imageUrlParent = '';
                                        // Create a timestamp
                                        var timestamp = new Date().getTime();

                                        //remove first character='.'
                                        imageUrlParent = baseUrl + result.fissureParent?.imagePath.slice(1) + "?t=" + timestamp;
                                        setPictureParent(imageUrlParent);
                                    }
                                }
                            setLoading(false);
                        }
                    })
                    .catch(function (error) {
                        setLoading(false);
                        setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                        console.log(error);
                        setErrors(error.message);
                    });
            }
        };

        setLoading(true);
        fetchOrientationType()
            .then(fetchPositionType())
            .then(fetchLargeurType())
            .then(fetchNiveauType())
            .then(fetchFissure());



    }, [updating]);

    useEffect(() => {
        if (currentFissure && currentFissure.idOrientation)
            setCurrentOrientation(orientationList.find(x => x.id == currentFissure.idOrientation));
        else
            setCurrentOrientation(null);


    }, [orientationList, currentFissure]);

    useEffect(() => {
        if (currentFissure && currentFissure.idNiveau)
            setCurrentNiveau(niveauList.find(x => x.id == currentFissure.idNiveau));
        else
            setCurrentNiveau(null);

    }, [niveauList, currentFissure]);

    useEffect(() => {
        if (currentFissure && currentFissure.idLargeur)
            setCurrentLargeur(largeurList.find(x => x.id == currentFissure.idLargeur));
        else
            setCurrentLargeur(null);


    }, [largeurList, currentFissure]);

    useEffect(() => {
        var isModified =
            currentFissure && currentFissure.fissureParent &&
            (currentFissure.idOrientation != currentFissure.fissureParent.idOrientation ||
                currentFissure.idLargeur != currentFissure.fissureParent.idLargeur ||
                currentFissure.longueur != currentFissure.fissureParent.longueur ||
                currentFissure.description != currentFissure.fissureParent.description ||
                picture != pictureParent);
        //console.log(isModified);
       //console.log(currentFissure?.idOrientation);
       // console.log(originalFissure?.idOrientation);
        setIsModifiedApresTravaux(isModified);
    }, [currentFissure, picture]);

    const labelStyle = {
        marginBottom: 0,
        fontSize: 14,
        marginLeft: 10,
    };
    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 10,
        width: 300,
        placeholder:null
    };
    const fieldStyleModified = {
        marginBottom: 0,
        marginLeft: 10,
        width: 300,
        placeholder: null,
        background:"lightgreen"
    };
    const errorLabelStyle = {
        marginBottom: -10,
        fontSize: 12,
        marginLeft: 10,
        color: "red",
        fontStyle: "italic",
       
    };
    const validateFields = () =>
    {
        return currentFissure && currentFissure.idFacade ?
            picture &&
            currentNiveau &&
            currentOrientation &&
            currentLargeur &&
            currentFissure.idNiveau && currentFissure.idLargeur && currentFissure.idOrientation && currentFissure.idPosition &&
            currentFissure.longueur > 0
            :   
            currentFissure && picture &&
            currentOrientation &&
            currentLargeur &&
            currentFissure.idLargeur && currentFissure.idOrientation &&
            currentFissure.longueur > 0;
    }

    let contentsFissure = loading ?
        <div>
            <p><em>{i18next.t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>

        </div>
        
        :
        errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{i18next.t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{i18next.t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                 
                <div>
                 
                
                    <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{fissureTitle}</h4>

                    <div>
                        <p style={labelStyle}><i>{i18next.t('Orientation')}  </i></p>
                        <ComboBox
                            required="true"
                            style={fieldStyle}
                            data={orientationList}
                            textField="libelle"
                            dataItemKey="id"
                            defaultItem="Orientation"
                            adaptive={true}
                            value={currentOrientation}
                            onChange={onChangeOrientation}
                            clearButton={false}
                            onClose={onCloseOrientation}
                        />
                        <br />
                        {!currentOrientation && <Label style={errorLabelStyle}>{i18next.t('RequiredField')} </Label>}


                        <p style={labelStyle}><i>{i18next.t('Longueur')} [m]  </i></p>
                        <NumericTextBox                            
                            required="true"
                            id="txtLongueur"                                                       
                            style={fieldStyle}
                            value={currentFissure.longueur}
                            step={0.1}
                            onChange={handleChangeLongueur}
                            //suffix={MetersSuffix}
                            format={"n2"}
                        />
                        <br/>
                        {!currentFissure.longueur && <Label style={errorLabelStyle}>{i18next.t('RequiredField')} </Label>}
                     
                       
                        <p style={labelStyle}><i>{i18next.t('Largeur')} </i></p>
                        <ComboBox
                            required="true"
                            style={fieldStyle}
                            data={largeurList}
                            textField="libelle"
                            dataItemKey="id"
                            defaultItem="Largeur"
                            adaptive={true}
                            value={currentLargeur}
                            onChange={onChangeLargeur}
                            clearButton={false}
                            onClose={onCloseLargeur}
                        />
                        <br />
                        {!currentLargeur && <Label style={errorLabelStyle}>{i18next.t('RequiredField')} </Label>}
                        {currentFissure.idFacade &&
                            <div>
                                <p style={labelStyle}><i>{i18next.t('Niveau')}  </i></p>
                                <ComboBox
                                    required={true}
                                    style={fieldStyle}
                                    data={niveauList}
                                    textField="libelle"
                                    dataItemKey="id"
                                    defaultItem="{niveauList.first()}"
                                    adaptive={true}
                                    value={currentNiveau}
                                    onChange={onChangeNiveau}
                                    clearButton={false}
                                    onClose={onCloseNiveau}
                                />
                                <br />
                                {!currentNiveau && <Label style={errorLabelStyle}>{i18next.t('RequiredField')} </Label>}

                        
                                <p style={labelStyle}><i>{i18next.t('Position')}  </i></p>
                                <Button togglable={true} style={{ margin: 10 }}
                                    selected={currentFissure.idPosition === leftId}
                                    onClick={() => setCurrentPositionFissure(leftId)}>Gauche</Button>
                                <Button togglable={true} style={{ margin: 10 }}
                                    selected={currentFissure.idPosition === centerId}
                                    onClick={() => setCurrentPositionFissure(centerId)}>Centre</Button>
                                <Button togglable={true} style={{ margin: 10 }}
                                    selected={currentFissure.idPosition === rightId}
                                    onClick={() => setCurrentPositionFissure(rightId)}>Droite</Button>
                                <br />
                            </div>
                        }
                        <p style={labelStyle}><i>{i18next.t('Description')}  </i></p>
                        <TextArea style={fieldStyle} rows={3} value={currentFissure.description} onChange={handleChangeDescription} />
                        <br /><br />
                    </div>
                    {!isMobile &&
                        <div>
                            <div>                          
                            {pictureParent &&
                                <div>
                                        <img style={{ height: 300 }} src={pictureParent} key="imageFissureParent" id="imageFissureParent" />
                                </div>
                            }

                        </div>
                          <div>
                                {picture != '' && (pictureParent=='' || picture!=pictureParent) ? (
                                <div>
                                    <Button icon='cancel'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOverwriteImage(true);
                                            setPicture('');
                                        }}
                                    >
                                    </Button>
                                    <br />
                                    <img style={{ height: 300 }} src={picture} key="imageFacade" id="imageFacade" />
                                </div>
                            ) :
                                (<div>
                                    <Button icon='camera'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                    >
                                    </Button>
                                    <Error>{i18next.t('TakePhoto')} </Error>
                                    <Webcam
                                        audio={false}
                                        height={300}
                                        ref={webcamRef}
                                        width={300}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                        forceScreenshotSourceSize="true"
                                    />
                                </div>
                                )}

                            </div>
                        </div>
                    }

                    {isMobile &&
                        <div>
                            <div>
                                {pictureParent &&
                                    <div>
                                        <img style={{ height: 300 }} src={pictureParent} key="imageFissureParent" id="imageFissureParent" />
                                    </div>
                                }

                            </div>
                            <div>
                                <Button icon='camera' onClick={handleClick}></Button>
                                <br />
                                <label>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        ref={handleFileInput}
                                        onChange={handleImageChange}
                                    />
                                </label>

                                {/*{imageObject && <img style={{height: 300 }} src={imageObject.imagePreview} />}*/}


                                {picture && (pictureParent == '' || picture != pictureParent) && <img style={{ height: 300 }} src={picture} />}

                            </div>
                        </div>
                    }
                    <br />
                                       
                </div>
              
            </div>
            : <p><em>{i18next.t(errors)}</em></p>;
    return (
        <div>
            {contentsFissure}

            {currentFissure && !currentFissure.fissureParent &&
                <div>
                    <Button icon="add" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }}
                        disabled={!validateFields()}
                        onClick={saveFissureAndAddFissure}>{i18next.t('AddDegat')}
                    </Button>

                    <br />
                    <Button icon="save" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }}
                        disabled={!validateFields()}
                        onClick={saveFissureAndReturn}>{i18next.t('SaveFissure')}</Button>
                    <br />  <br /> 

                </div>
            }

            {currentFissure && currentFissure.fissureParent &&
                <div>
                    <Button icon="save" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }} 
                        disabled={!validateFields()}
                        onClick={validate}>{isModifiedApresTravaux ? i18next.t('ValidateWithChanges'):i18next.t('ValidateNoChanges')}
                    </Button>

                    <br />
                    {/*<Button style={{ marginLeft: 10, marginTop: 10, borderColor: "orange", borderWidth: "5px" }}*/}
                    {/*    disabled={!validateFields()}*/}
                    {/*    onClick={validateWithChanges}>{i18next.t('ValidateWithChanges')}</Button>*/}
                    <br />  <br /> 
                </div>
            }
        </div>);
}
export default withTranslation()(Fissure);
