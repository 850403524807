import * as React from "react";
import {
    GridColumnMenuFilter,
    GridColumnMenuCheckboxFilter,
    GridColumnMenuSort,
    GridColumnMenuGroup,
} from "@progress/kendo-react-grid";


export const ColumnMenu = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} />
            <GridColumnMenuGroup {...props} />
        </div>
    );
};
export const ColumnMenuCheckboxFilter = (props) => {
    return (
        <div class="box_GridColumnMenuCheckbox">
            <GridColumnMenuCheckboxFilter
                {...props}
                expanded={true}
                data={props.data} uniqueData={true} searchBox={() => null}
            />
        </div>
    );
};