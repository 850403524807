import React from 'react';
//import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createRoot } from 'react-dom/client';
import './i18n';

//import '@progress/kendo-theme-default/dist/default-blue.scss';

//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>
//);

import './styles/App.css';



//import '@progress/kendo-theme-default/dist/default-blue.scss';
//import '@progress/kendo-theme-default/dist/default-main.scss';
//import '@progress/kendo-theme-default/dist/default-nordic.scss';
//import '@progress/kendo-theme-default/dist/default-urban.scss';
//import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
//import '@progress/kendo-theme-material/dist/material-main.scss';
//import '@progress/kendo-theme-material/dist/material-arctic.scss';
//import '@progress/kendo-theme-material/dist/material-pacific.scss';
//import '@progress/kendo-theme-material/dist/material-sky.scss';
//import '@progress/kendo-theme-material/dist/material-nova.scss';
//import '@progress/kendo-theme-material/dist/material-burnt-teal.scss';
//import "@progress/kendo-theme-bootstrap/dist/bootstrap-nordic.scss";
//import "@progress/kendo-theme-bootstrap/dist/bootstrap-main.scss";
//import "@progress/kendo-theme-bootstrap/dist/bootstrap-urban.scss";

import './bootstrap-metrix/dist/css/bootstrap-metrix.css';

import { HashRouter as Router } from 'react-router-dom';
//import { BrowserRouter as Router } from "react-router-dom";
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Router ><App /></Router>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
