import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { postDataForLogin } from "../AccessAPI";
import SessionManager from "./SessionManager";

import { Button } from "@progress/kendo-react-buttons";
import i18next from 'i18next';
import { Icon } from "@progress/kendo-react-common";

import { Dialog } from "@progress/kendo-react-dialogs";
import {  InputPrefix  } from "@progress/kendo-react-inputs";
import { withTranslation } from 'react-i18next';
import { Checkbox } from "@progress/kendo-react-inputs";
import { React, useState, useEffect } from 'react';
function Login(props) {
   
   const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            login();
        }
    }

    const [formState, setFormState] = useState({

    });

    const [errorMessage, setErrorMessage] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const handleChange = (event) => {
        let name = event.target.name || event.target.element.current.name;
        setFormState({ ...formState, [name]: event.target.value });

    };
    useEffect(() => {
        const loggedInUser = localStorage.getItem("rememberUserMetrix");
        if (loggedInUser) {
            try {
                const foundUser = JSON.parse(loggedInUser);
                console.log(foundUser)

                executeLogin(foundUser);
            } catch (e) { console.log(e); }
        }
    }, []);

    const executeLogin = (result) => {
        SessionManager.setUserSession(result.name, result.token, result.userId, result.role)
        if (SessionManager.getToken()) {

            //window.location.href = "/";

            props.onLogin();
        }
    };

    const login= async() => {
      

        let result = await postDataForLogin('/api/auth/Login', formState);

        if (result?.token) {

            if (rememberMe === true)
                localStorage.setItem('rememberUserMetrix', JSON.stringify(result))
            executeLogin(result);

        }

        else {
            let errors = '';
            for (const key in result?.errors) {
                if (Object.hasOwnProperty.call(result.errors, key)) {
                    errors += result.errors[key];

                }
            }
            errors = errors === '' ? 'Login is unsuccessfull!' : errors;
            toast.error(errors, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            setErrorMessage("Login failed!");
            
        }


    }


    let content = <Dialog >
        <div className="row">
            <div className="login-form">
                <h3>Metrix Constats</h3>

                <div className="input-container">
                    <InputPrefix >
                        <Icon name="user" />
                        <input
                            type="text"
                            className="loginField"
                            //className="form-control"
                            placeholder={i18next.t("PleaseEnterUsername")}
                            name="userName"
                            onChange={handleChange}
                            onKeyDown={onKeyDown}

                        /></InputPrefix>
                    {/*<label>Username </label>*/}

                </div>
                <div className="input-container">
                    {/* <label >Password </label>*/}
                    <InputPrefix className="input-prefix" >
                        <Icon name="lock" />
                        <input type="password"
                            className="loginField"
                            rounded="medium"
                            size="large"
                            placeholder={i18next.t("PleaseEnterPassword")}
                            name="password"
                            onChange={handleChange}
                            onKeyDown={onKeyDown}

                        />
                    </InputPrefix>
                </div>
                <div className="input-container">
                    <Checkbox label={i18next.t("Remember me")} value={rememberMe} onChange={(e)=>setRememberMe(e.value) } />
                </div>
                <div className="button-container">
                    <Button themeColor={"primary"} className="loginButton" onClick={login}>
                        Sign In
                    </Button>
                </div>

                <div className="row">
                    <div className="col-md-8" >
                        <strong className="has-error">{errorMessage}</strong>
                    </div>
                    <div className="col-md-4">
                        <ToastContainer></ToastContainer>
                    </div>
                </div >

            </div>
        </div>
    </Dialog>;


    return (<div>{content}</div>);
};



export default withTranslation()(Login);