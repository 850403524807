import { React, useState, useRef, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import i18next from 'i18next';
import { useParams, useNavigate, Link } from "react-router-dom";
import 'react-html5-camera-photo/build/css/index.css';
import { getBaseURL } from "../AccessAPI";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs"; 
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import {
    FloatingLabel,
    Label,
    Hint,
    Error,
} from "@progress/kendo-react-labels";
import { Fade } from "@progress/kendo-react-animation";
const videoConstraints = {
    width: 600,
    height: 600,
    facingMode: 'environment',
}

function Mur({ t }) {
    let { constatid, murid } = useParams();
    const [title, setTitle] = useState(t('CreateMurTitle')); 
    const [pieceTitle, setPieceTitle] = useState('');
    const [zoneTitle, setZoneTitle] = useState('');
    const [constatId, setConstatId] = useState('');
    const [mandatId, setMandatId] = useState('');  
    const [zoneId, setZoneId] = useState('');  
    const [parcelleTitle, setParcelleTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [mandatTitle, setMandatTitle] = useState('');
    const [isApresTravaux, setIsApresTravaux] = useState(false);
    const navigate = useNavigate();
   
    const [picture, setPicture] = useState('')
    const webcamRef = useRef(null);
    const [currentMur, setCurrentMur] = useState(null);
    const [overwriteImage, setOverwriteImage] = useState(false);
    const [errors, setErrors] = useState([]);

    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot({ width: 3840, height: 3840 });
        //console.log(pictureSrc);
        setOverwriteImage(true);
        setPicture(pictureSrc);
    });
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const labelStyle = {
        marginBottom: 0,
        fontSize: 14,
        marginLeft: 0,
    };
    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 0,
        width: 300,
        placeholder: null
    };


    //const [imageObject, setImageObject] = useState(null);

    const handleFileInput = useRef(null);

    const handleClick = () => {
        handleFileInput.current.click();
    };

    const handleImageChange = (event) => {

        try {
            let file = event.target.files[0];


            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let pictureFromTempFile = reader.result.toString();
                setPicture(pictureFromTempFile);
                setOverwriteImage(true);
              
            };
            reader.onerror = (error) => {
                console.log(error);
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('Error') });
            };
        }
        catch (e) {
            console.log(e);
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('Error') });
        }
    };

    const handleChangeDescription = useCallback(
        (e) => {
            setCurrentMur(existingValues => ({
                ...existingValues,
                description: e.value,
            }))
        },
        [setCurrentMur]
    );

 
    const navigateToPiece = () => {
        navigate("/constatint/" + constatId + "/zone/" + zoneId + "/piecemurs/" + currentMur.idPiece);
    }
    const navigateToDegat = () => {
       // navigate("/constatint/" + constatId + "/zone/" + zoneId + "/piecemurs/" + currentMur.idPiece + "/mur/" + currentMur.id + "/fissure/create" );
        navigate("/constatint/" + constatId + "/zone/" + zoneId + "/piecemurs/" + currentMur.idPiece + "/mur/" + currentMur.id + "/degat");

    }

    const { success, error, warning, info, none, notificationMessage } = notificationState;
    const saveMur = async () => {
        setLoading(true);

        console.log('Saving mur');
        const dataItemMur = {
            idConstat: constatid,
            id: murid,
            idPiece: currentMur.idPiece,
            nom: currentMur.nom,
            imageString: picture,
            overwriteImage: overwriteImage,
            description: currentMur.description,
            isEditedByUser:true
        };
        
        const result = await MandatManager.updateMur(dataItemMur);

        if (result && result.errors == null && result.imageFileExists) {
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessSavingObject') });

            setLoading(false);

            navigateToPiece();
        }
        else if (result && !result.imageFileExists) {
            //error image file does not exist on server
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ImageFileMissing') });

            setLoading(false);

        }
        else {
            //error object not saved
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') });

            setLoading(false);

        }
        return result;
    };

    const addDegat = async () => {        
        saveMur().then(async () => {
           
        navigateToDegat();
               
        });
       
    };


    useEffect(() => {
        const fetchData = async () => {
            console.log('fetch mur detail');
            let baseUrl = await getBaseURL();
            MandatManager.getMurDetail(murid)
                .then(function (result) {

                    if (result) {
                        setZoneId(result.idZone);
                        setConstatId(result.idConstat);
                        setMandatId(result.idMandat);
                        setPieceTitle(result.pieceDescription);
                        setZoneTitle(result.zoneDescription);
                        setMandatTitle(result.mandatDescription);
                        setParcelleTitle(result.parcelleDescription);


                        if (result.idParent) {
                            setIsApresTravaux(true);
                        }

                        if (result.imageString)
                        {
                            setPicture('data:image/png;base64,' + result.imageString);
                            setOverwriteImage(false);
                        }
                        else {

                            var imageUrl = '';
                            if (result.imagePath) {
                                // Create a timestamp
                                var timestamp = new Date().getTime();

                                //remove first character='.'
                                imageUrl = baseUrl + result.imagePath.slice(1) + "?t=" + timestamp;
                            }
                            setOverwriteImage(false);
                            setPicture(imageUrl);

                        }
                        setMandatId(result.idMandat);

                        setCurrentMur(result);
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    // setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };
        fetchData();

    }, []);


  

    let contentsMur = loading ?
        <div>
            <p><em>{i18next.t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
            {/*<Loader type="infinite-spinner" size="large" themeColor="success"></Loader>*/}
        </div>
        :
        errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <div>{t(notificationMessage)}</div>
                            </Notification>
                        )}                       
                        
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span style={{ whiteSpace: "pre-line", maxWidth: 200 }}>{t(notificationMessage)}</span >
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div>
                    <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{title}</h4>


                    <Link to={'/constatint/' + constatid}>
                        <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
                    </Link>
                    <Link to={'/mandatdetail/' + mandatId}>
                        <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
                    </Link>
                    <Link to={'/constatint/' + constatId + '/zone/' + zoneId}>
                        <p style={{ marginLeft: 10 }}><i>{zoneTitle} </i></p>
                    </Link>                   
                    <Link to={'/constatint/' + constatId + '/zone/' + zoneId + '/piecemurs/' + currentMur.idPiece}>
                        <p style={{ marginLeft: 10 }}><i>{pieceTitle} </i></p>
                    </Link>
                    <p style={{ marginLeft: 10 }}><i><b>{currentMur.nom}</b> </i></p>
                    {!isMobile && 
                        <div>
                            {picture != '' ? (
                                <div>
                                    <Button icon='cancel'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOverwriteImage(true);
                                            setPicture('');
                                        }} disabled={isApresTravaux}
                                    >
                                    </Button>
                                    <br/>
                                    <img style={{ height: 300 }} src={picture} key="imageMur" id="imageMur" />
                                </div>
                            ) :
                                (<div>
                                    <Button icon='camera'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }} disabled={isApresTravaux}
                                    >
                                    </Button>
                                    <Error>{t('TakePhoto')} </Error>
                                    <Webcam
                                        audio={false}
                                        height={300}
                                        ref={webcamRef}
                                        width={300}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                        forceScreenshotSourceSize="true"
                                    />
                                </div>
                                )}

                        </div>
                    }

                    {isMobile &&
                       
                        <div>
                            <Button icon='camera' onClick={handleClick} disabled={isApresTravaux}></Button>
                            <br/>
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    ref={handleFileInput}
                                    onChange={handleImageChange}
                                />
                            </label>
                           
                            {/*{imageObject && <img style={{height: 300 }} src={imageObject.imagePreview} />}*/}

                     
                            {picture && <img style={{ height: 300 }} src={picture} />}

                        </div>
                        
                    }
                    <br />

                    <p style={labelStyle} ><i>{t('Description')}  </i></p>
                    <TextArea style={fieldStyle} rows={3} value={currentMur.description} onChange={handleChangeDescription} disabled={isApresTravaux} />
                    <br />

                    <Button icon="add" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }} disabled={!picture}
                        onClick={addDegat}>{t('AddDegat')}
                    </Button>
                   
                    <br />
                    <Button icon="save" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }} disabled={!picture}
                        onClick={saveMur}>{t('SaveMur')}</Button>
                    <br /><br />
                   
                </div>
            </div>
            : <p><em>{t(errors)}</em></p>;;
    return (
        <div>

            {contentsMur}
        </div>);
}
export default withTranslation()(Mur);
