const folderNameRegex = new RegExp('^[a-zA-ZÀ-ÖØ-öø-ÿ0-9_+-.\u0027 \u00C0-\u024F\u1E00-\u1EFF]+$');

const Validators = {
    nameValidator(value) {
       return !value
            ? "Le nom est obligatoire !"
           : !folderNameRegex.test(value) ? 
                 "Le nom contient au moins un caractère interdit !"
                : "";
    }
}
export default Validators;