import React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { withRouter } from './withRouter';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import SessionManager from './auth/SessionManager';

const Title = (props) => {
    return (
        <React.Fragment>
            {props.content}
            <span className="k-spacer" />
            <span
                className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button k-disabled"

            >
                <span className={`k-icon ${props.icon}`} />
            </span>
        </React.Fragment>
    );
};
function CustomTabStrip(props)
{
   
    const [state, setState] = React.useState({
        expanded: true,
        location: "/",
        tabs: props.pages,
        setTabs: props.pages,
        selected: props.selectedtab
    });
 
    

    const handleSelect = (e) => {


        setState(state, {
            selected: e.selected,
        });


        const selectedTab = props.pages[e.selected];
        props.navigate(selectedTab.route);
        
        setState({
            ...state,
            location: selectedTab.route, selected: e.selected 
        });

        SessionManager.setSelectedTab(e.selected);
    };

    return (
       <div>
            <TabStrip

                selected={state.selected}
                onSelect={handleSelect}
                
            > 
                {state.tabs.map((item, index) => {
                    return (

                        <TabStripTab

                            title={<Title content={i18next.t(item.title)} icon={item.icon} />}
                            key={index}   >
                            <div>
                                <div>{props.children}</div>

                            </div>
                        </TabStripTab>

                    );
                })}
            </TabStrip>
        </div>
    );
}


export default withRouter(withTranslation()(CustomTabStrip));

