import { Route, Routes } from 'react-router-dom';
import MenuNavContainer from './MenuNavContainer';
import React from 'react';
import MandatDetail from './mandat/MandatDetail';
import Mandats from './mandat/Mandats';
import Users from './user/Users';
import Rights from './user/Rights';
import Constat from './mandat/Constat';
import ConstatInt from './mandat/ConstatInt';
import Facade from './mandat/Facade';
//import Fissure from './mandat/Fissure';
import Zone from './mandat/Zone';
import PieceMurs from './mandat/PieceMurs';
import Mur from './mandat/Mur';
import Piece from './mandat/Piece';
import Degat from './mandat/Degat';
import AutreDegatDetail from './mandat/AutreDegatDetail';
import FissureDetail from './mandat/FissureDetail';
function ReactComponent(props) {

   
    return (
        <MenuNavContainer props={props }>
            <Routes>
                <Route exact={true} path="/" element={<Mandats></Mandats>} children={Mandats} />

                <Route path="/mandatdetail">
                    <Route path=":id" element={<MandatDetail ></MandatDetail>} />
                </Route>

                <Route path="/constat">
                    <Route path=":id" element={<Constat></Constat>} />
                </Route>
                {/*<Route path="/facade/:id" element={<Facade></Facade>}>*/}
                    
                {/*</Route>*/}
                <Route path="/constat/:constatid/facade/:facadeid" element={<Facade></Facade>}></Route>
                <Route path="/constat/:constatid/facade/:facadeid/fissure/:numero" element={<FissureDetail></FissureDetail>}></Route>
                <Route path="/constat/:constatid/facade/:facadeid/autredegat/:numero" element={<AutreDegatDetail></AutreDegatDetail>}></Route>
                <Route path="/constat/:constatid/facade/:facadeid/degat" element={<Degat></Degat>}></Route>
                <Route exact={true} path="/users" element={<Users></Users>}  />

                <Route path="/constatint">
                    <Route path=":id" element={<ConstatInt></ConstatInt>} />
                </Route>

                <Route path="/constatint/:constatid/zone/:zoneid" element={<Zone></Zone>}></Route>
                
                <Route path="/constatint/:constatid/zone/:zoneid/piecemurs/:id" element={<PieceMurs></PieceMurs>}></Route>

                <Route path="/constatint/:constatid/zone/:zoneid/piecemurs/:pieceid/mur/:murid" element={<Mur></Mur>}></Route>
                <Route path="/constatint/:constatid/zone/:zoneid/piecemurs/:pieceid/mur/:murid/fissure/:numero" element={<FissureDetail></FissureDetail>}></Route>
                <Route path="/constatint/:constatid/zone/:zoneid/piecemurs/:pieceid/mur/:murid/autredegat/:numero" element={<AutreDegatDetail></AutreDegatDetail>}></Route>
                <Route path="/constatint/:constatid/zone/:zoneid/piecemurs/:pieceid/mur/:murid/degat" element={<Degat></Degat>}></Route>
                <Route path="/constatint/:constatid/zone/:zoneid/piecedetail/:pieceid" element={<Piece></Piece>}></Route>

                <Route exact={true} path="/rights" element={<Rights></Rights>} />

            </Routes>
        </MenuNavContainer>
    );
}

export default ReactComponent;