import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import UserManager from './UserManager';
import ValidationInput from '../controls/ValidationInput';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

const initialSort = [{
    field: 'roleName',
    dir: 'asc'
}];



function EditUser(props) {
    const [loading, setLoading] = React.useState(true);
    const [allRoles, setAllRoles] = React.useState([]);
    const [sort, setSort] = React.useState(initialSort);
    const [visibility, setVisibility] = React.useState(props.item ? false : true);
    const [title, setTitle] = React.useState(props.item ? "EditUser" : "NewUser");
    const [password, setPassword] = React.useState("");
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const emailValidator = (value) => (emailRegex.test(value) ? "" : i18next.t('ErrorEmailNotValid'));


    const confirmPasswordValidator = (value) => {

        if (password == value)
            return "";
        else
            return i18next.t('ErrorPasswordNotMatch');

    }

    const passwordValidator = (value) => {

        if (value == null)
            return i18next.t('ErrorPasswordNull');
        else if (value.length < 6)
            return i18next.t('ErrorPasswordLength');
        else
            return "";

    };


    React.useEffect(() => {
        const fetchData = async () => {
            const result = await UserManager.getRoles();

            if (result)
            {
                const roles = result.map(obj => `${obj.name}`);

                setAllRoles(roles);
                setLoading(false);
            }
           

        };

        fetchData();
    }, []);

   

    const handlePasswordChange = (event) => {
        if (event.target.name === "password") {
            setPassword(event.target.value);
        }
    };

    let contents = loading
        ? <p><em>{i18next.t('Loading')} </em></p>
        :
        <Dialog title={i18next.t(title)} onClose={props.cancelEdit}>
            <Form
                onSubmit={props.onSubmit}
                initialValues={props.item}
                render={(formRenderProps) => (
                    <FormElement
                        style={{
                            width: 670,
                        }}>
                        <fieldset className={"k-form-fieldset"}
                            style={{
                                margin: 10, width: "650px"
                            }}
                        >
                            
                                    <div className="mb-3">
                                        <Field
                                            name={"lastName"}
                                            component={Input}
                                            label={i18next.t('LastName')}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"firstName"}
                                            component={Input}
                                            label={i18next.t('FirstName')}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"roleName"}                                             
                                            component={ComboBox}
                                            data={allRoles}
                                            label={i18next.t('Role')}
                                            editable={false}

                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"userName"}
                                            component={Input}
                                            label={i18next.t('Login')}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Field
                                            name={"email"}
                                            component={ValidationInput}
                                            validator={emailValidator}
                                            label={i18next.t('Email')}
                                        />
                                    </div>
                                    {visibility ? (
                                        <div>
                                            <div className="mb-3">
                                                <Field
                                                    name={"password"}
                                                    component={ValidationInput}
                                                    type={"password"}
                                                    label={i18next.t('Paswword')}
                                                    validator={passwordValidator}
                                                    onMouseLeave={handlePasswordChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    name={"confirmationPassword"}
                                                    component={ValidationInput}
                                                    type={"password"}
                                                    label={i18next.t('ConfirmationPaswword')}
                                                    validator={confirmPasswordValidator}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                 

                             

                        </fieldset>
                        <div className="k-form-buttons" style={{
                            display: "flex", justifyContent: 'flex-end',
                            margin: 10, width: "580px", float: "right", color: 'red' 
                        }}>
                            {i18next.t(props.errorMessage)}
                            </div>
                        <div className="k-form-buttons" style={{
                            display: "flex", justifyContent: 'flex-end',
                            margin: 10, width: "580px", float: "right"
                        }}>

                          
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                disabled={!formRenderProps.allowSubmit}  >
                                {i18next.t('Update')}
                            </button>
                            <button
                                type={"submit"}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={props.cancelEdit} >
                                {i18next.t('Cancel')}
                            </button>


                        </div>
                      
                    </FormElement>
                )}
            />
        </Dialog>

    return (
        <div>
            {contents}

        </div>

    );
};
export default withTranslation()(EditUser);
