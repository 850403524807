import { React, useState, useEffect } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import CommandCellUser from '../controls/CommandCellUser';
import UserRightsDialog from './UserRightsDialog';
import { withTranslation } from 'react-i18next';
import { Button } from "@progress/kendo-react-buttons";
import EditUser from './EditUserDialog';
import UserManager from './UserManager';
import { ColumnMenuCheckboxFilter } from '../controls/ColumnMenu';
import SessionManager from "../auth/SessionManager";
import { Fade } from "@progress/kendo-react-animation";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";


import { filterBy } from "@progress/kendo-data-query";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "fullTextSearch",
            operator: "contains",
            value: "",
        },
    ],
};

const initialSort = [{
    field: 'lastName',
    dir: 'asc'
}];

const filterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ]

};

function Users({ t }) {

    const [users, setUsers] = useState([]);
    const [sort, setSort] = useState(initialSort);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [openFormMandats, setOpenFormMandats] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [filter, setFilter] = useState(initialFilter);
    const [errors, setErrors] = useState('');

    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    }
    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const MyColumnMenuCheckboxFilter = (props) => <ColumnMenuCheckboxFilter {...props} data={users} />

    const ResCommandCell = (props) => (
        <CommandCellUser
            {...props}
            edit={enterEdit}
            viewMandats={viewMandats}
            remove={remove}
            disableRemove={(props.dataItem.userName === SessionManager.getUserName()) ? true : false}
            canEdit={true}
            canViewMandats={true }
        />
    );

    const remove = async (dataItem) => {

        const result = await UserManager.deleteUser(dataItem);
        if (result) {
            let responseJson = result;
            if (responseJson == true) {
                const data = users;
                let index = data.findIndex((record) => record.id === dataItem.id);
                data.splice(index, 1);

                setUsers(data);
                refresh();
            }
        }


    }

    const add = async (dataItem) => {
        dataItem.inEdit = false;
        let result = await UserManager.createUser(dataItem);
        if (result) {
            let reseauJson = result;

            if (reseauJson?.id) {
                reseauJson.id = reseauJson.id;
                reseauJson.firstName = reseauJson.firstName;
                reseauJson.lastName = reseauJson.lastName;
                reseauJson.userName = reseauJson.userName;
                reseauJson.roleName = reseauJson.roleName;
                reseauJson.fullTextSearch = reseauJson.fullTextSearch;
                reseauJson.newUser = true;


                setUsers([...users, reseauJson]);
                refresh();


                setErrorMessage("");
                setOpenForm(false);

            }
            else {
                const errors = reseauJson.errors.map(obj => `${obj.code}`);
                setErrorMessage(errors[0]);
            }
        }
    };

    const handleUpdate = async (user) => {
        if (user.id) {
            update(user);
        }
        else
            add(user);
    };

    const update = async (user) => {
        const resultUpdate = await UserManager.updateUser(user);
        if (resultUpdate == true) {

            const result = await UserManager.getUserDetails(user);
            let responseJson = result;
            if (responseJson) {
                let newItems = users.map((item) => {
                    if (responseJson.id === item.id) {
                        item = { ...responseJson };

                    }

                    return item;
                });

                setUsers(newItems);
                setLoading(false);
                refresh();
                if (sendEmail == true) {
                    user.newUser = false;
                    await UserManager.sendEmail(user);
                }
            }
            setErrorMessage("");
            setOpenForm(false);

        }
        else
        {
            setErrorMessage(resultUpdate);
        }
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = users.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [field]: event.value }
                : item
        );
        setUsers(newData);
    };

    
    const enterEdit = async (item) => {
        setOpenForm(true);
        setEditItem(item);
        setSendEmail(false);
    };

    const viewMandats = async (item) => {
        setOpenFormMandats(true);
        setEditItem(item);
    };

    const onUserCreate = (e) => {
        setOpenForm(true);
        setEditItem(null);
        setSendEmail(false);

    }

    const handleCancelEdit = () => {
        setErrorMessage("");
        setOpenForm(false);
    };

    const handleCloseViewMandats = () => {
        setErrorMessage("");
        setOpenFormMandats(false);
    };

    const onFilterChange = (event) => {
        var filter = { logic: 'or', filters: [] };
        filter.filters.push({
            field: "fullTextSearch",
            operator: 'contains',
            value: event.target.value
        })
        setUsers(users);
        setFilter(filter);
    };

    useEffect(() => {
        const fetchData = async () => {
            UserManager.getUsers()
                .then(function (result) {

                    if (result) {
                        result.map((item) => {
                            item.id = item.id;
                            item.userName = item.userName;
                            item.roleName = item.roleName;
                            item.lastName = item.lastName;
                            item.firstName = item.firstName;
                            item.email = item.email;
                            item.fullTextSearch = item.fullTextSearch;
                        });

                        setUsers(result);
                        setLoading(false);
                        setSort(sort);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };
        fetchData();

    }, []);

    useEffect(() => {
        setUsers(users);

    }, [updating]);

    let contents = loading
        ? <p><em>{t('Loading')} </em></p>
        : errors.length == 0 ?
        <div>
            <NotificationGroup
                style={{
                    right: 0,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                }} >
                <Fade>
                    {error && (
                        <Notification
                            type={{
                                style: "error",
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => setNotificationState({ ...notificationState, error: false })}>
                            <span>{t(notificationMessage)}</span>
                        </Notification>
                    )}
                </Fade>
            </NotificationGroup>

            <div style={{ marginLeft: 10 }}>
                <Grid
                    name="UsersGrid"
                    data={filterBy(orderBy(users, sort), filter)}
                    dataItemKey={"id"}
                    sort={sort}
                    sortable={true,
                    {
                        allowUnsort: false,
                        mode: "single"
                    }}
                    onItemChange={itemChange}
                    onSortChange={
                        e => {

                            setSort(e.sort);
                            setUsers(orderBy(users, e.sort));
                            refresh();

                        }
                    }
                    filterable={true}
                    filter={filter}
                    filterOperators={filterOperators}
                    onFilterChange={(e) => {
                        setFilter(e.filter);
                    }} >
                    <GridToolbar>
                        <Button icon="plus" themeColor={"secondary"}
                            onClick={() => onUserCreate()}>{t('NewUser')}</Button>
                        <div style={{
                            margin: 10, width: "300px", height: "25px"
                        }} >
                        </div>
                        <span>{t('Search')}</span>
                        <input id='filter' onChange={onFilterChange}
                            style={{
                                margin: 10, width: "300px", height: "25px"
                            }} />


                    </GridToolbar>

                    <Column field="lastName" filterable={false} title={t('LastName')} />
                    <Column field="firstName" filterable={false} title={t('FirstName')} />
                    <Column field="roleName" columnMenu={MyColumnMenuCheckboxFilter} filterable={false} title={t('Role')} />
                    <Column field="userName" filterable={false} title={t('Login')} />
                 
                    <Column cell={ResCommandCell} filterable={false} />

                </Grid>
                {openForm && (
                    <EditUser
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleUpdate}
                        item={editItem} errorMessage={errorMessage} 
                        
                    />
                )}

                    {openFormMandats && (
                        <UserRightsDialog
                            closeViewMandats={handleCloseViewMandats }
                            onSubmit={handleUpdate}
                            item={editItem} errorMessage={errorMessage}

                        />
                    )}

                </div>
               

        </div>

            : <p><em>{t(errors)}</em></p>;

    return (
        <div>
            <h2 style={{ marginLeft: 10 }}>{t('Users')}</h2>

            {contents}

        </div>
    );


}

export default withTranslation()(Users);
