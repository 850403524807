
import { React, useState, useEffect, useCallback } from 'react';

import UsersSelection from './UsersSelection';
import MandatsSelection from '../mandat/MandatsSelection';
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { withTranslation } from 'react-i18next';
import UserManager from './UserManager';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";

import { Fade } from "@progress/kendo-react-animation";

function Rights({ t }) {


    const [selectedUsers, setSelectedUsers] = useState({});
    const [selectedMandats, setSelectedMandats] = useState({});
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });
    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const assignRights = async () => {
        //console.log('assignRights');

        //console.log('users');
        //console.log(selectedUsers);

        //console.log('mandats');
        //console.log(selectedMandats);

        const resultUpdate = await UserManager.setRights({ usersIds: selectedUsers, mandatsIds: selectedMandats });
        if (resultUpdate == true) {
            console.log('rights assigned!');
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('RightsAssigned') });
          
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorRightsAssigned') });
        }

    }

    const removeRights = async () => {
        //console.log('removeRights');

        //console.log('users');
        //console.log(selectedUsers);

        //console.log('mandats');
        //console.log(selectedMandats);

        const resultUpdate = await UserManager.removeRights({ usersIds: selectedUsers, mandatsIds: selectedMandats });
        if (resultUpdate == true) {
            console.log('rights removed!');
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('RightsRemoved') });
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorRightsRemoved') });
        }

    }

    const setUsers = (data) => {

        console.log(data);
        let usersIds = Object.keys(data).map(function (key) { if (data[key] == true) return parseInt(key); else return null; });
        usersIds = usersIds.filter(x => x != null);
        console.log(usersIds);
        setSelectedUsers(usersIds);
    }
    const setMandats = (data) => {

        console.log(data);
        let mandatsIds = Object.keys(data).map(function (key) { if (data[key] == true) return parseInt(key); else return null; });
        mandatsIds = mandatsIds.filter(x => x != null);
        console.log(mandatsIds);
        setSelectedMandats(mandatsIds);
    }
    return (
        <div>
            <NotificationGroup
                style={{
                    right: 0,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                }} >
                <Fade>
                    {error && (
                        <Notification
                            type={{
                                style: "error",
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => {
                                setNotificationState({ ...notificationState, error: false });
                                window.location.reload();
                            }}>
                            <span>{t(notificationMessage)}</span>
                        </Notification>
                    )}
                    {success && (
                        <Notification
                            type={{
                                style: "success",
                                icon: true,
                            }}
                            closable={true}
                            onClose={() => {
                                setNotificationState({ ...notificationState, success: false });
                                window.location.reload();
                            }
                            }>
                            <span>{t(notificationMessage)}</span>
                        </Notification>
                    )}
                </Fade>
            </NotificationGroup>

            <div>
                <h2 style={{ marginLeft: 10 }}>{t('Rights')}</h2>
                <table><tbody>
                    <tr>
                        <td style={{ width: `50%` }}>
                            <MandatsSelection setMandats={setMandats} selectedMandats={selectedMandats}></MandatsSelection>
                        </td>
                        <td style={{ width: `50%` }}>
                            <UsersSelection setUsers={setUsers} selectedUsers={selectedUsers}></UsersSelection>
                        </td>
                    </tr>

                </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Button icon="check" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10, width: 200 }}
                        onClick={assignRights}>{t('AssignRights')}</Button>

                    <Button icon="close" themeColor={"secondary"} style={{ marginLeft: 10, marginTop: 10, width: 200 }}
                        onClick={removeRights}>{t('RemoveRights')}</Button>
                </div>


            </div>
        </div>

    );



}
export default withTranslation()(Rights); 