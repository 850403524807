import { React, useState, useEffect, cloneElement } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import { Button } from "@progress/kendo-react-buttons";
import {  useNavigate } from 'react-router-dom';
import { withRouter } from '../withRouter';
import { Fade } from "@progress/kendo-react-animation";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { filterBy } from "@progress/kendo-data-query";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "fullTextSearch",
            operator: "contains",
            value: "",
        },
    ],
};

const initialSort = [{
    field: 'numero',
    dir: 'asc'
}];

const filterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ]

};

function Mandats({ properties,t }) {
    const [mandats, setMandats] = useState([]);
    const [sort, setSort] = useState(initialSort);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [errors, setErrors] = useState('');
    const [editItem, setEditItem] = useState({});
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };

    const { success, error, warning, info, none, notificationMessage } = notificationState;
    const navigate = useNavigate(); 

    const onFilterChange = (event) => {
        var filter = { logic: 'or', filters: [] };
        filter.filters.push({
            field: "fullTextSearch",
            operator: 'contains',
            value: event.target.value
        })
        setMandats(mandats);
        setFilter(filter);
    };
    const EditCommandCell = (props) => {
        return (
            <td>
                <Button icon="edit" themeColor={"primary"}
                    onClick={() => props.enterEdit(props.dataItem)}
                >
                    
                </Button>
            </td>
        );
    };
    const MyEditCommandCell = (props) => (
        <EditCommandCell {...props} enterEdit={enterEdit} />
    );
    const enterEdit = (item) => {
        navigate("/mandatdetail/"+item.id);
     
    };
    const rowRender = (trElement, dataItem, myProp) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: () => {
                console.log('Double click on ' + dataItem.dataItem.id, myProp)
                navigate("/mandatdetail/" + dataItem.dataItem.id);
            },
            onContextMenu: (e) => {
                e.preventDefault()
                console.log('Right Click on ' + dataItem.dataItem.id, myProp)
            },
        };
        return cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetch mandats');
            MandatManager.getMandats()
                .then(function (result) {

                    if (result) {
                        result.map((item) => {
                            item.id = item.id;
                            item.numero = item.numero;
                            item.clientName = item.clientName;
                            item.fullTextSearch = item.fullTextSearch;
                        });

                        setMandats(result);
                        setLoading(false);
                        setSort(sort);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };
        fetchData();

    }, []);

    useEffect(() => {
        setMandats(mandats);

    }, [updating]);


    let contents = loading
        ? <p><em>{t('Loading')} </em></p>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
                    <Grid 
                        name="MandatsGrid"
                        data={filterBy(orderBy(mandats, sort), filter)}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}
                     
                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setMandats(orderBy(mandats, e.sort));
                                refresh();

                            }
                        }
                        rowRender={(trElement, dataItem) => rowRender(trElement, dataItem, { myProp: "test" })}
                        filterable={true}
                        filter={filter}
                        filterOperators={filterOperators}
                        onFilterChange={(e) => {
                            setFilter(e.filter);
                        }}
                        resizable={true}
                    >
                        <GridToolbar>
                          

                            <input id='filter' onChange={onFilterChange} placeholder={t('Search')}
                                style={{
                                    marginLeft: 10, width: "250px", height: "25px"
                                }} />

                        </GridToolbar>
                        
                        <Column field="numero" filterable={false} title={t('Mandat')} />
                        <Column field="clientName" filterable={false} title={t('ClientName')} />
                        <Column cell={MyEditCommandCell} filterable={false} />

                    </Grid>
                   
                </div>

            </div>

            : <p><em>{t(errors)}</em></p>;

    return (
        <div>
            {/*<h4 style={{ marginLeft: 10 , marginTop:-10}}>{t('Mandats')}</h4>*/}

            {contents}

        </div>
    );
}
export default withRouter(withTranslation()(Mandats));
