import * as React from 'react';
import SessionManager from './components/auth/SessionManager';
import Login from './components/auth/Login';
import { withTranslation } from 'react-i18next';
import appConfig from './appConfig.json';
import AppMenuTabStrip from './components/AppMenuTabStrip';
import AppPages from './AppPages';
import AppStatusBar from './components/AppStatusBar';
import AppMenuNavContainer from "./components/AppMenuNavContainer";
function App({ t }) {

    const checkInterval = appConfig.intervalCheckSession;
    const [intervalId, setIntervalId] = React.useState(null);
    const logOff = (e) => {
        SessionManager.removeUserSession();
        localStorage.clear();
        window.location.reload();
    }

   
    const setLogoffTimer = () => {
        let intervalIds = setInterval(() => {
            if (SessionManager.getToken() == null) {
                logOff();
            }
        }, checkInterval);


        setIntervalId(intervalIds);
    }

    const [expandedLeftMenu, setExpandedLeftMenu] = React.useState(appConfig.expandedMenu);
    const expandLeftMenu = () => {
        setExpandedLeftMenu(!expandedLeftMenu);
    }

    const tabs = AppPages.tabs;
    const selectedtab = SessionManager.getSelectedTab();
   
    return (

        SessionManager.getToken() ? (
            //autorized
            <React.Fragment>
               {/* <div>Metrix Constats Web Application</div>*/}

                <AppStatusBar
                    onExpandMenuClicked={expandLeftMenu}
                    onLogOffClicked={logOff}

                >

                </AppStatusBar>

                {/*{<AppMenuTabStrip pages={tabs} selectedtab={selectedtab}></AppMenuTabStrip>}*/}
               
                {<AppMenuNavContainer ></AppMenuNavContainer>}

               
            </React.Fragment>

        ) :
            (
        //not authorized, show login form
        <Login onLogin={setLogoffTimer}></Login>
            )
    );
}

export default withTranslation()(App);

