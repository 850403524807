import { React, useEffect, useState, cloneElement } from 'react';

import './styles/gridNoHeader.css';
import { Grid, GridColumn as Column, GridToolbar, GridHeader } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Fade } from "@progress/kendo-react-animation";
import CommandCell from "../controls/CommandCell";
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";

import { Button } from "@progress/kendo-react-buttons";

import CommandCellConstat from "../controls/CommandCellConstat";

import TemplatePDFDialog from './TemplatePDF';

import MandatManager from './MandatManager';

import { getBaseURLAPI } from '../AccessAPI';

const DateCell = ({ myDate }) => {
    if (myDate) {
        const [year, month, day] = myDate.split('T')[0].split('-');
        const time_part = myDate.split('T')[1];

        return (
            <td>
                {`${year}/${month}/${day} ${time_part}`}
            </td>
        );

    }
    else
        return <td>

        </td>
}
function Constats(props) {
    const [constatsExt, setConstatsExt] = useState();
    const [constatsInt, setConstatsInt] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [errors, setErrors] = useState('');
    const [itemConstat, setItemConstat] = useState("");
    const [openFormTemplate, setOpenFormTemplate] = useState(false);
    const [selectedValue, setSelectedValue] = useState("SignatureMetriX");
    const [itemConstatComplement, setItemConstatComplement] = useState(null);
    const [typeConstatPdf, setTypeConstatPdf] = useState("EXT");
    const [dateReleve, setDateReleve] = useState("");
    const [intervalId, setIntervalId] = useState(null);
        const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };

    const { success, error, warning, info, none, notificationMessage } = notificationState;
    


    const EditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            //view={navigateToSuiviMandat}
            remove={remove}
            canEdit={true}
            //canView={true}
            title={i18next.t('Constat')}
            canRemove={!isMobile}
        />
    );

    const CommandCellConstatInt = (props) => (
        <CommandCellConstat
            {...props}
            download={onClickCreatePDFInternal}
            view={onClickViewConstatInternal}                    
        />
    );


    const CommandCellConstatExt = (props) => (
        <CommandCellConstat
            {...props}
            download={onClickCreatePDF}
            view={onClickViewConstat}
        />
    );

    const onClickViewConstat = async (item) => {
        if (item) {
            navigate("/constat/" + item.id);
        }
    };

    const onClickCreatePDF = async (item) => {

        setItemConstat(item);
        setSelectedValue("SignatureMetriX");
        setTypeConstatPdf("EXT");
        var indexConstatExt = constatsExt.findIndex(x => x.id == item.id);
        var constatIntSameIndex = constatsInt.filter((item, index) => index == indexConstatExt).map((itemInt, indexInt) => {
            return itemInt;
});            
        setItemConstatComplement(constatIntSameIndex.length > 0 ? constatIntSameIndex[0]:null);
        setOpenFormTemplate(true);

    };
    const onClickCreatePDFInternal = async (item) => {

        setItemConstat(item);
        setSelectedValue("SignatureMetriX");
        setTypeConstatPdf("INT");
        var indexConstatInt = constatsInt.findIndex(x => x.id == item.id);
        var constatExtSameIndex = constatsExt.filter((item, index) => index == indexConstatInt).map((itemInt, indexInt) => {
            return itemInt;
        });
        setItemConstatComplement(constatExtSameIndex.length > 0 ? constatExtSameIndex[0]: null);
        setOpenFormTemplate(true);

    };

    const onClickViewConstatInternal = async (item) => {
        if (item) {
            navigate("/constatint/" + item.id);
        }
    };

    const handleCancelPDF = () => {
        setOpenFormTemplate(false);
    };


    const handleCreatePDF = async () => {

        let dateMDY = '';
        if (dateReleve.toString().length > 1) {
            let month = dateReleve.getMonth();
            let day = dateReleve.getDate();
            let year = dateReleve.getFullYear();
            month = month + 1;
            if (month.toString().length == 1) {
                month = '0' + month;
            }
            if (day.toString().length == 1) {
                day = '0' + day;
            }
            dateMDY = day + '-' + month + '-' + year;
        }


        setDateReleve('');
        const dataToPDF = typeConstatPdf == "EXT" ?
            { IdConstat: itemConstat?.id, TypeSignature: selectedValue, DateReleve: dateMDY, IdTypeConstat: itemConstat?.idType, IdConstatComplement: itemConstatComplement?.id }
            :
            { IdConstat: itemConstat?.id, TypeSignature: selectedValue, DateReleve: dateMDY, IdTypeConstat: itemConstat?.idType, IdConstatComplement: itemConstatComplement?.id };

        setLoadingTemplate(true);

        try {
            let result = await MandatManager.createPDF(dataToPDF);
            if (result && result.fileName) {
                if (result.isTimedout == false) {
                    let pdfFileURL = await getBaseURLAPI() + "/FilesPdf/" + result.fileName;
                    window.open(pdfFileURL);
                    console.log('no timeout, pdf opened');
                    setLoadingTemplate(false);
                    setOpenFormTemplate(false);
                }
                else {
                     console.log('timeout pdf, retry...');
                     setCheckFileTimer(result.fileName);
                }

            }
        }
        catch (ex) {
            console.log(ex);
            
        }
        finally {
            setLoadingTemplate(false);
            setOpenFormTemplate(false);
        }

     

    };
    const [isOpened, setIsOpened] = useState(false);
    
    const setCheckFileTimer = async (filename) => {
        setIsOpened(false);
        let intervalIds = setInterval(async () => {
            console.log('check pdf file ' + filename);
            let existsFile = await MandatManager.getFilePDF(filename);
            if (existsFile) {
                let pdfFileURL = await getBaseURLAPI() + "/FilesPdf/" + filename;
                window.open(pdfFileURL);
                console.log('file opened ' + pdfFileURL);
                setLoadingTemplate(false);
                setOpenFormTemplate(false);
                setIsOpened(true);
            }
        }, 10000);


        setIntervalId(intervalIds);
        
    };

    const handleChange = async (e) => {

        setSelectedValue(e.value);
        
    };

    //const handleChangeConstatComplement = async (e) => {

    //    setItemConstatComplement(e.value);

    //};

    const handleChangeDateReleve = async (e) => {

        setDateReleve(e.value);

    };

    const onClickCreateConstatInternal = async (item) => {

        let result = await MandatManager.createConstatInternal(item.id);
        if (result) {

            if (result?.id) {
                setErrorMessage('');
                navigate("/constatint/" + result.id);
            }
            else {
                setErrorMessage(i18next.t('ErrorCreatingObject'));
            }
        }
        else {
            setErrorMessage(i18next.t('ErrorCreatingObject'));
        }
    };

    const onClickCreateConstat = async (item) => {

        let result = await MandatManager.createConstat(item.id);
        if (result) {

            if (result?.id) {
                setErrorMessage('');
                navigate("/constat/" + result.id);
            }
            else {
                setErrorMessage(i18next.t('ErrorCreatingObject'));
            }
        }
        else {
            setErrorMessage(i18next.t('ErrorCreatingObject'));
        }
    };


    useEffect(() => {
        //var gridConstatsInt = document.getElementsByClassName("k-grid-header");
        //if (!gridConstatsInt) return;
        //gridConstatsInt[0].setAttribute("style", "display: none");
        populateConstatsData();
    }, []);

    useEffect(() => {
        if (isOpened) {
            clearInterval(intervalId);
}
    }, [isOpened]);
    async function populateConstatsData() {
        console.log(props);
        console.log('fetch constats');

        var constats = props.mandat.constats.map((item,index) => {
            item.date = item.date==null?null: new Date(item.date);
            item.description = item.codeTravaux + ' ' +( item.date!=null? (item.date.getDate()+'.'+item.date.getMonth()+'.'+ item.date.getFullYear()):'');
        
            return item;
        });
        var constatsExterieur = constats.filter((item) => item.codeType == "EXT").map((item, index) => {
            //item.description = props.mandat.mandat.numero + '.' + props.mandat.parcelle.numero + '.' + (index < 8 ? '0' + (index + 1).toString() : (index + 1).toString());
            item.descriptionPdf = props.mandat.mandat.numero + '.' + props.mandat.parcelle.numero + '.' + (index < 8 ? '0' + (index + 1).toString() : (index + 1).toString());
            return item;
        });
        setConstatsExt(constatsExterieur);
        
        var constatsInterieur = constats.filter((item) => item.codeType == "INT").map((item, index) => {
            //item.description = props.mandat.mandat.numero + '.' + props.mandat.parcelle.numero + '.' + (index < 8 ? '0' + (index + 1).toString() : (index + 1).toString());
            item.descriptionPdf = props.mandat.mandat.numero + '.' + props.mandat.parcelle.numero + '.' + (index < 8 ? '0' + (index + 1).toString() : (index + 1).toString());
            return item;
        });
        setConstatsInt(constatsInterieur);

              
        setLoading(false);
    }
    
    let contents = loading
        ? <p><em>Loading... </em></p>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{"t(notificationMessage)"}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 0, marginTop: 20 }}>
                    <label style={{ fontSize: "14px" }} ><b>{i18next.t("ConstatsExt")}</b></label>

                    
                    <Grid
                        name="ConstatsExtGrid"
                        data={constatsExt}
                        dataItemKey={"id"}
                       
                        resizable={true}

                    >
                       
                        {/*<Column field="codeTravaux" filterable={false} width={55} title="  " />*/}
                        {/*<Column field="date" filterable={false} format="{0:dd.MM.yyyy}" title="  " />*/}
                        <Column field="description" filterable={false} width={isMobile ? 100 : 200}  title="  " />

                        <Column cell={CommandCellConstatExt} filterable={false} />

                    </Grid>

                    <Button themeColor={"primary"} icon='add' className="buttonPlus" style={{ marginTop: 10 }}
                        onClick={() => onClickCreateConstat(props.mandat)}  >
                      
                    </Button>
                </div>

               
                <div style={{ marginLeft: 0 , marginTop:20}}>
                    <label style={{ fontSize: "14px" } } ><b>{i18next.t("ConstatsInt")}</b></label>
                    <Grid 
                        name="ConstatsIntGrid"
                        id='ConstatsIntGrid'
                        data={constatsInt}
                        dataItemKey={"id"}
                      
                        resizable={true}
                    >

                        {/*<Column field="codeTravaux" filterable={false}  width={55} headerHeight={0} title="  "/>*/}
                        {/*<Column field="date" filterable={false}  format="{0:dd.MM.yyyy}" title="  " />*/}
                        <Column field="description" filterable={false} width={isMobile ? 100 : 200} title="  " />

                        <Column cell={CommandCellConstatInt} filterable={false}  />

                    </Grid>

                    <Button themeColor={"primary"} icon='add' className="buttonPlus" style={{ marginTop: 10 }}
                        onClick={() => onClickCreateConstatInternal(props.mandat)}  >
                       
                    </Button>
                </div>

                {
                    openFormTemplate && <TemplatePDFDialog
                        cancelPDF={handleCancelPDF}
                        okPDF={handleCreatePDF}
                        selectedValue={selectedValue}
                        dateReleve={dateReleve}
                        handleChange={handleChange}
                        handleChangeDateReleve={handleChangeDateReleve}
                        itemConstat={itemConstat}
                        itemConstatComplement={itemConstatComplement}
                        loadingTemplate={loadingTemplate }
                    />
                }

            </div>

            : <p><em>{t(errors)}</em></p>;

    return (
        <div>
       
            {contents}

        </div>
    );
}

export default withTranslation()(Constats);