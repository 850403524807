import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ConfirmationDialog from './ConfirmationDialog';

const CommandCell = (props) => {

    const { dataItem } = props;
    let nom = "";
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    
    const onConfirmDelete = () => {
        setConfirmDialog(false);
        props.remove(dataItem);
    }

    const onClose = () => {
        setConfirmDialog(false);
    }
    if (props.dataItem) {
        nom = props.dataItem.Nom ?? props.dataItem.userName ?? props.dataItem.label ?? props.dataItem.id;
    }

    return (
        <td className="k-command-cell">
            {(props.canEdit) &&
                <Button icon='edit' themeColor={"primary"}
                    onClick={() =>
                        props.edit(dataItem)}></Button >
            }
            {(!props.dataItem.disableRemove)
                && < Button icon='delete'
                    onClick={() =>
                        setConfirmDialog(true)}>
                </Button>}

            {confirmDialog &&
                <ConfirmationDialog
                    onConfirm={onConfirmDelete}
                    onClose={onClose}
                    Message={i18next.t('ConfirmDelete') + ' ' + nom + ' ?'}
                    Title={i18next.t('Delete') + ' ' + i18next.t(props.title)} />}
        </td>
    );
}
export default withTranslation()(CommandCell);
