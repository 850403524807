import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
const DropDownCell = (props) => {
   
    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.id,
            });
        }
    };
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    return (
        <td>
            {dataItem.inEdit ? (
                <DropDownList
                    style={{
                        width: "150px", wordWrap: "break-word",whiteSpace: 'pre-wrap', overflowWrap: 'break-word' 
                    }} 
                    onChange={handleChange}
                    value={props.dataItems.find((c) => c.id === dataValue)}
                    data={props.dataItems}
                    textField="libelle"
                />
            ) : (
                    props.dataItems!=null?  props.dataItems.find((c) => c.id === dataValue)?.libelle:""
            )}
        </td>
    );
};
export default DropDownCell;

