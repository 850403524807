
import React, {  useState, useEffect, useCallback } from 'react';
import {
    Grid,
    GridColumn as Column,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { withTranslation } from 'react-i18next';
import UserManager from './UserManager';

import { orderBy } from '@progress/kendo-data-query';
import { Fade } from "@progress/kendo-react-animation";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";


// @ts-expect-error
import products from "./products.json";
// @ts-expect-error

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);



import { filterBy } from "@progress/kendo-data-query";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "fullTextSearch",
            operator: "contains",
            value: "",
        },
    ],
};

const initialSort = [{
    field: 'lastName',
    dir: 'asc'
}];

const filterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ]

};
function UsersSelection(props)
{
  
    const [users, setUsers] = useState([]);
    const [sort, setSort] = useState(initialSort);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [filter, setFilter] = useState(initialFilter);
    const [errors, setErrors] = useState('');
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });
    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const [dataState, setDataState] = useState(
        users.map((dataItem) =>
            Object.assign(
                {
                    selected: false,
                },
                dataItem
            )
        )
    );
    const [selectedState, setSelectedState] = useState(props.selectedUsers);
    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
            props.setUsers(newSelectedState);
        },
        [selectedState]
    );
    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        props.setUsers(newSelectedState);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            UserManager.getUsers()
                .then(function (result) {

                    if (result) {
                        result.map((item) => {
                            item.id = item.id;
                            item.userName = item.userName;
                            item.roleName = item.roleName;
                            item.lastName = item.lastName;
                            item.firstName = item.firstName;
                            item.email = item.email;
                            item.fullTextSearch = item.fullTextSearch;
                            item.selected = false;
                        });

                        setUsers(result);

                        setSort(sort);
                        refresh();


                        setLoading(false);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };
        fetchData();

    }, []);
    useEffect(() => {
        setUsers(users);

    }, [updating]);
    useEffect(() => {
        setDataState(users.map((dataItem) =>
            Object.assign(
                {
                    selected: false,
                },
                dataItem
            )
        ));
    }, [users]);
   

    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    }

    let contents = loading
        ? <p><em>{props.t('Loading')} </em></p>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{props.t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
                    <Grid
                        data={filterBy(orderBy(dataState.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        })), sort), filter)}
                        style={{
                            height: "400px",
                        }}

                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setUsers(orderBy(users, e.sort));
                                refresh();

                            }
                        }


                        filterable={true}
                        filter={filter}
                        filterOperators={filterOperators}
                        onFilterChange={(e) => {
                            setFilter(e.filter);
                        }}

                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                        }}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                    >
                        <Column
                            field={SELECTED_FIELD}
                            width="40px"
                            filterable={false} 
                        />
                        <Column field="userName" filterable={true} title={props.t('Login')} />

                    </Grid>
                </div>
            </div> : <p><em>{props.t(errors)}</em></p>;


    return (
        <div >
            <h3 style={{ marginLeft: 10 }}>{props.t('Users')}</h3>

            {contents}

        </div>
    );
};

export default withTranslation()(UsersSelection); 